<template>
	<div>
		<template v-if="speakers.length">
			<div class="flex-container tw-justify-center lg:tw-justify-start">
				<div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 tw-mt-5">
					<!-- Card -->
					<div
						v-for="speaker in speakers"
						:key="speaker.contentID"
					>
						<div
							class="tw-relative md:tw-max-h-60 tw-overflow-hidden tw-rounded-2xl"
						>
							<img
								v-if="getCustomField( speaker, 'photo' )"
								:src="'/'+ getCustomField( speaker, 'photo' )"
								:alt="speaker.title"
								class="tw-w-64"
							>
							<img
								v-else
								src="../../../images/events/speakers/default-image.png"
								:alt="speaker.title"
								class="tw-w-64"
							>
							<img
								v-if="getCustomField( speaker, 'flagImage' )"
								:src="'/' + getCustomField( speaker, 'flagImage' )"
								:alt="getCustomField( speaker, 'country' ) ? getCustomField( speaker, 'country' ) + '-flag' : 'country-flag'"
								class="tw-absolute tw-top-2 tw-right-2"
								width="34"
							/>
						</div>
						<div class="card-body pt-5">
							<div class="tw-text-lg tw-font-black">
								{{ speaker.title }}
							</div>
							<div class="tw-text-sm tw-text-gray-500">
								{{ getCustomField( speaker, 'position' ) }}
							</div>
							<div class="social tw-text-sm">
								<a
									:href="getCustomField( speaker, 'twitterLink' )"
									target="_blank"
									class="tw-flex"
									:aria-label="'Follow ' + speaker.title + ' on Twitter'"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										height="16"
										width="16"
										viewBox="0 0 512 512"
										v-if="getCustomField( speaker, 'twitterUsername' )"
										class="tw-mr-1"
										aria-hidden="true"
									>
										<!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc. -->
										<path
											fill="#0074A0"
											d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
										/>
									</svg>
									{{ getCustomField( speaker, 'twitterUsername' ) }}
								</a>
							</div>
						</div>
					</div>
					<!-- Card -->
				</div>
			</div>
		</template>
		<template v-else>
			<div class="tw-text-center">
				<p class="tw-text-gray-700 tw-text-3xl">
					No Speakers found
				</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props : {
		speakers : {
			type     : Array,
			required : true
		}
	},
	methods : {
		/**
		* Get customfield from an object, returns empty string when it doesn't exists
		* @param {Object} obj The object containing the customfield
		* @param {String} fieldName The name of the custom field
		*/
		getCustomField( obj, fieldName ) {
			let field = obj.customFields.find( ( field ) => field.key === fieldName );
			return field != undefined ? field.value : "";
		}
	}
};
</script>