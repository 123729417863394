<template>
	<div>
		<div class="">
			<p class="tw-text-gray-600 tw-text-md md:tw-w-1/2 tw-text-left">
				<b>Categories:</b>
				<input
					id="searchCategory"
					type="text"
					v-model="search"
					placeholder="Search Category"
					class="tw-border-b-1 tw-border-gray-400 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-appearance-none
					tw-bg-transparent tw-text-gray-600 tw-p-3 tw-leading-tight
					focus:tw-outline-none focus:tw-ring-transparent focus:tw-bg-gray-300 focus:tw-border-gray-400"
				/>
			</p>
		</div>
		<div
			v-if="filteredList.length"
			class="tw-space-x-1 tw-space-y-1 tw-my-3 tw-text-justify"
		>
			<a
				v-for="cat in filteredList"
				:key="cat.categoryID"
				class="tw-inline-flex tw-items-center tw-px-3 tw-py-0.5 tw-rounded-full tw-text-sm
				tw-font-medium tw-text-gray-800 tw-border tw-border-gray-500
				hover:tw-text-gray-800 hover:tw-text-font-bold focus:tw-text-gray-700 focus:tw-ring-offset-gray-700"
				:href="`./blog/category/` + cat.slug"
				:title="`Filter by ` + cat.category"
				:alt="`Filter by ` + cat.category"
			>
				{{ cat.category + " ("+cat.numberOfEntries+")" }}
			</a>
		</div>
		<div v-else>
			<div class="tw-text-center tw-pb-3">
				<em class="tw-text-gray-600 tw-tracking-tight">
					<b><i class="far fa-frown" /></b> No matches found
				</em>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name  : "CategorySearch",
	props : {
		categories : {
			type     : Array,
			required : true
		}
	},
	data() {
		return { search: "" };
	},
	computed : {
		filteredList() {
			return this.categories.filter( cat => {
				return this.plainText( cat.category ).toLowerCase().includes( this.search.toLowerCase() );
			} );
		}
	},
	methods : {
		plainText( text ) {
			return text.replace( /<[^>]+>/g, "" );
		}
	}
};
</script>