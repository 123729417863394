<template>
	<div>
		<div class="tw-grid tw-content-center tw-items-center tw-grid-flow-col tw-auto-cols-max">
			<p class="tw-text-gray-600 tw-text-md tw-text-left tw-mb-0">
				<b>Archives:</b>
			</p>
			<select
				v-model="selectedMonth"
				class="tw-w-full tw-ml-1 tw-text-gray-700 tw-mt-1 tw-block tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
			>
				<option
					value=""
					disabled
					hidden
				>
					Month
				</option>
				<option
					v-for="( month, index ) in filteredMonth"
					:value="index"
					:key="month"
				>
					{{ monthNames[ month - 1 ] }}
				</option>
			</select>
			<select
				v-model="selectedYear"
				class="tw-ml-1 tw-text-gray-700 tw-mt-1 tw-block tw-w-auto tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
			>
				<option
					value=""
					disabled
					hidden
				>
					Year
				</option>
				<option
					v-for="year in filteredYear"
					:key="year"
				>
					{{ year }}
				</option>
			</select>
		</div>

		<div
			v-if="filteredData.length"
			class="tw-space-x-2 tw-space-y-2 tw-my-3 tw-text-justify"
		>
			<a
				v-for="archive in filteredData"
				:key="archive.year"
				:href="`./blog/archives/${archive.year}/${archive.month}`"
				class="tw-inline-flex tw-items-center tw-px-3 tw-py-0.5 tw-rounded-full tw-text-sm
				tw-font-medium tw-text-gray-800 tw-border tw-border-gray-800
				hover:tw-text-gray-800 hover:tw-text-font-bold focus:tw-text-gray-700 focus:tw-ring-offset-gray-700"
			>
				{{ monthNames[ archive.month - 1 ] + " " + archive.year + " (" + archive.count + ")" }}
			</a>
		</div>
		<div v-else-if="!filteredData.length && selectedYear.length">
			<div class="tw-text-center tw-pb-3">
				<em class="tw-text-gray-600 tw-tracking-tight">
					<b><i class="far fa-frown" /></b> No matches found
				</em>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name  : "ArchivesSearch",
	props : {
		archives : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			search        : "",
			selectedMonth : "",
			selectedYear  : "",
			archivesArray : this.filterObject( this.archives ),
			monthNames    : [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			]
		};
	},
	computed : {
		filteredMonth(){
			return ( [ ...new Set( this.archivesArray.map( item => item.month ) ) ] ).sort( function( a,b ) {
				return a - b;
			} );
		},
		filteredYear(){
			return [ ...new Set( this.archivesArray.map( item => item.year ) ) ];
		},
		filteredData(){
			if ( this.selectedYear.length && this.selectedMonth.length ) { return []; }
			return this.archivesArray.filter( item => {
				return item.year == this.selectedYear && item.month == ( this.selectedMonth + 1 );
			} );
		}
	},
	methods : {
		filterObject( collection ) {
			var result = new Array();
			for ( var j = 1; j < Object.keys( collection ).length; j++ ) {
				result.push( collection[j] );
			}
			return result;
		}
	}
};
</script>