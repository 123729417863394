<template>
	<div>
		<template v-if="slides.length">
			<div class="tw-flex tw-mb-4 tw-flex-wrap">
				<div class="md:tw-w-8/12">
					<iframe
						class="tw-w-full md:tw-h-96 tw-h-60"
						:src="getCustomField( activeSlide, 'src' )"
						frameborder="0"
						scrolling="no"
						allowfullscreen
					/>
					<div class="tw-mt-4">
						<div class="tw-font-extrabold tw-text-2xl">
							{{ activeSlide.title }}
						</div>
						<div class="tw-flex tw-text-base tw-text-wrap tw-mb-4">
							<p v-if="activeSlide.speaker">
								<span class="tw-font-bold">
									By {{ activeSlide.speaker.title }}
								</span>
								<span class="tw-mx-2">
									from
								</span>
								<span class="tw-font-bold">
									{{ getCustomField( activeSlide.speaker, 'company' ) }}
								</span>
							</p>
						</div>
						<div v-html="activeSlide.content" />
						<div
							v-if="getCustomField( activeSlide, 'siteLink' )"
							class=" tw-mt-4"
						>
							<a
								target="_blank"
								:href="getCustomField( activeSlide, 'siteLink' )"
								class="tw-font-bold tw-text-sm"
							>
								DOWNLOAD SLIDE
								<i
									aria-hidden="true"
									class="fa fa-download"
								/>
							</a>
						</div>
					</div>
				</div>
				<div class="md:tw-w-4/12">
					<ul class="tw-overflow-auto tw-list-none tw-ml-4 tw-mr-0 tw-max-h-96">
						<li
							v-for="slide in slides"
							:key="slide.contentID"
							@click="updateSlide( slide )"
							class="tw-p-3 tw-rounded-lg tw-mb-2 tw-cursor-pointer"
							:class="[ activeSlide.contentID === slide.contentID ? 'tw-text-white tw-bg-blue-900' : '' ]"
						>
							<div class=" tw-font-semibold tw-mb-2">
								{{ slide.title }}
							</div>
							<div
								v-if="slide.speaker"
								class="tw-flex tw-items-center"
							>
								<img
									v-if="getCustomField( slide.speaker, 'photo' )"
									:src="'/' + getCustomField( slide.speaker, 'photo' )"
									alt="speaker-image"
									class=" tw-rounded-full tw-mr-4"
									width="34"
								/>
								By {{ slide.speaker.title }}
							</div>
						</li>
					</ul>
					<div class="tw-flex tw-justify-end tw-text-gray-500">
						Total {{ slides.length }} talks
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="tw-text-center">
				<p class="tw-text-gray-700 tw-text-3xl">
					No Slides found
				</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props : {
		slides : {
			type     : Array,
			required : true
		}
	},
	data() {
		return { activeSlide: this.slides[0] };
	},
	methods : {
		/**
		* Updates the current slide to show
		* @param {Object} slide The slide object
		*/
		updateSlide( slide ) {
			this.activeSlide = slide;
		},
		/**
		* Get customfield from an object, returns empty string when it doesn't exists
		* @param {Object} obj The object containing the customfield
		* @param {String} fieldName The name of the custom field
		*/
		getCustomField( obj, fieldName ) {
			let field = obj.customFields.find( ( field ) => field.key === fieldName );
			return field != undefined ? field.value : "";
		}
	}
};
</script>