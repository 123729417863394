<template>
	<div>
		<div class="card-sponsor tw-bg-gray-50 tw-max-w-sm tw-rounded tw-overflow-hidden tw-shadow-lg tw-relative tw-w-28 md:tw-w-32 tw-h-40">
			<img
				:src="src"
				:alt="name"
				class="tw-w-28 md:tw-w-32 tw-h-28"
			>
			<div class="tw-px-3 tw-py-1 tw-bg-gray-900 tw-text-white text-center tw-leading-none tw-h-12">
				<span class="tw-text-sm md:tw-text-md tw-font-bold">
					{{ name }}
				</span>
			</div>
			<div class="card-hover tw-opacity-0 text-center bg-green-opacity tw-w-full tw-h-full tw-m-auto tw-rounded tw-absolute tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center">
				<a
					:href="linkedin"
					target="_blank"
					class="tw-text-gray-800 hover:tw-text-white"
				>
					<i
						class="fab fa-linkedin tw-text-xl"
						aria-hidden="true"
					/>
					<p>linkedin</p>
				</a>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name  : "Card",
	props : {
		// Sponsor's name
		name : {
			type     : String,
			required : true
		},
		// Image url
		src : {
			type     : String,
			required : true
		},
		// Sponsor's linkedin
		linkedin : {
			type     : String,
			required : true
		},
	}
};
</script>