<template>
	<div>
		<div class="tw-my-5 tw-flex tw-flex-row tw-space-x-4 tw-justify-center md:tw-justify-end tw-items-center tw-flex-wrap tw-space-y-2 md:tw-space-y-0">
			<button
				v-for="( month, index ) in webinarMonths()"
				:key="index"
				class="tw-bg-transparent hover:tw-bg-blue-600 tw-text-blue-800 tw-font-semibold hover:tw-text-white tw-py-2 tw-px-4 hover:tw-border-transparent tw-rounded"
				@click="setMonth( month )"
				:class="selectedMonth == monthNames.indexOf( month ) ? 'tw-border tw-border-blue-600':''"
			>
				{{ month }}
			</button>
		</div>

		<div v-if="selectedWebinar.length">
			<div
				v-for="( webinar, index ) in selectedWebinar"
				:key="index"
				class="tw-flex tw-flex-col md:tw-flex-row tw-bg-gray-200 tw-rounded-xl tw-mt-10 tw-shadow-md tw-h-auto md:tw-h-80"
			>
				<div class="box-img-upcoming tw-rounded-l-lg md:tw-w-1/2">
					<img
						:src="webinar.featuredImageURL"
						class="tw-rounded-l-lg"
						:alt="webinar.title"
					>
				</div>
				<div class="tw-flex tw-flex-col tw-justify-between tw-rounded-r-xl tw-p-4 md:tw-w-1/2">
					<div class="tw-text-lg tw-text-gray-800">
						<p>
							{{ webinar.content }}
						</p>
					</div>
					<div class="tw-flex tw-justify-between tw-items-center">
						<div class="tw-text-md tw-text-gray-800 tw-flex">
							<img
								v-for="( avatar, item ) in webinar.avatars"
								:key="item"
								:src="avatar.avatarLink"
								:alt="avatar.authorName"
								:title="avatar.authorName"
								class="gravatar img-circle tw-w-8 tw-h-8 tw-mr-1"
							/>
						</div>
						<a
							:href="getRecordingUrl( webinar ).length ? getRecordingUrl( webinar ) : getRegistrationUrl( webinar )"
							v-text="getRecordingUrl( webinar ).length ? 'SEE RECORDING' : 'REGISTER TODAY'"
							class="btn btn-primary"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<p class="tw-text-gray-700 tw-text-3xl tw-text-center tw-mt-10">
				<i
					class="far fa-frown"
					aria-hidden="true"
				/>
				No webinars matches
			</p>
		</div>

		<div class="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-space-y-3 md:tw-space-y-0 tw-space-x-5 tw-mt-10">
			<img
				v-for="( webinar, index ) in upcomingWebinars"
				:key="webinar.webinarDate"
				:src="webinar.featuredImageURL"
				:alt="webinar.title"
				:title="webinar.title"
				class="tw-rounded tw-w-24 tw-h-auto tw-shadow-md tw-cursor-pointer"
				:class="index > 0 ? 'tw-opacity-75': ''"
				@click="selectedMonth = ( new Date( webinar.webinarDate ) ).getMonth()"
			>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			today         : new Date(),
			monthName     : "",
			selectedMonth : ( new Date ).getMonth(),
			monthNames    : [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			]
		};
	},
	props : {
		webinars : {
			type     : Array,
			required : true
		}
	},
	computed : {
		// Set the next webinars after the selected webinar
		upcomingWebinars(){
			return this.webinars.filter( item => {
				return new Date( item.webinarDate ) >= new Date( this.today.getFullYear(),this.selectedMonth, 1, 0,0,0,0 );
			} );
		},
		// Set the webinar selected
		selectedWebinar(){
			var month = this.selectedMonth;
			if ( !( this.webinars.find( ( webinar ) => ( new Date( webinar.webinarDate ) ).getMonth() == Number.parseInt( month ) ) ) ){
				month++;
			}

			return this.webinars.filter( item => {
				return ( new Date( item.webinarDate ) ).getMonth() == Number.parseInt( month );
			} );
		}
	},
	methods : {
		// Update selected month variable via month name
		setMonth( monthName ){
			this.selectedMonth = this.monthNames.indexOf( monthName );
		},
		// Method to get webinars months name in order way
		webinarMonths(){
			var dates = [ ...new Set( this.webinars.map( item => item.webinarDate ) ) ];

			dates.sort( function( a,b ) {
				return new Date( a ) - new Date( b );
			} );

			var startMonth  = ( new Date( dates[0] ) ).getMonth();

			var months = [];
			var counter = 0;
			do {
				months.push( this.monthNames[ startMonth ] );
				startMonth = ( ( startMonth + 1 ) >= 12 ) ? 0 : ( startMonth + 1 );

				counter++;
			} while ( counter < 6 );

			return months;
		},
		// Method to get the webinar recording URL
		getRecordingUrl( webinar ){
			return webinar.customFields.filter( ( item ) => item.key == "RecordingUrl" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );
		},
		// Method to get the webinar registration URL
		getRegistrationUrl( webinar ){
			return webinar.customFields.filter( ( item ) => item.key == "registrationUrl" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );
		}
	}
};
</script>