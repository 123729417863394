<template>
	<div
		class="tw-flex tw-flex-wrap lg:tw-space-x-3 tw-space-y-2 lg:tw-space-y-3 tw-justify-between tw-items-center"
	>
		<slot />
	</div>
</template>
<script>
export default {
	data() {
		return { cards: [] };
	},
	mounted(){
		this.cards = this.$slots.default
			.filter( ( c ) => c.componentOptions && c.componentOptions.tag === "card" )
			.map( ( c ) => c.componentInstance );
	}
};
</script>