// Import our Bootstrap extensions
import "bootstrap-sass/assets/javascripts/bootstrap.js";
import "owl.carousel/dist/owl.carousel.min.js";
import "../vendor/syntaxhighlighter/syntaxhighlighter.js";
import "../vendor/syntaxhighlighter/prismjs/prism.js";
import Cookies from "js-cookie";
import Vue from "vue";

Vue.component( "MediaOwlCarousel", require( "./components/media-owl-carousel" ).default );
Vue.component( "ContentStoreOwlCarousel", require( "./components/content-store-owl-carousel" ).default );
Vue.component( "MainNav", require( "./components/MainNav" ).default );
Vue.component( "Slider", require( "./components/slider/Slider" ).default );
Vue.component( "Slide", require( "./components/slider/Slide" ).default );
Vue.component( "CategorySearch", require( "./components/CategorySearch" ).default );
Vue.component( "ArchivesSearch", require( "./components/ArchivesSearch" ).default );
Vue.component( "HallOfFame", require( "./components/sponsors/Hall-of-fame" ).default );
Vue.component( "Card", require( "./components/sponsors/Card" ).default );
Vue.component( "Webinars", require( "./components/events/Webinars" ).default );
Vue.component( "WebinarUpcoming", require( "./components/events/WebinarUpcoming" ).default );
Vue.component( "WebinarCard", require( "./components/events/WebinarCard" ).default );
Vue.component( "EventsExplorer", require( "./components/events/EventsExplorer" ).default );
Vue.component( "EventCard", require( "./components/events/EventCard" ).default );
Vue.component( "CoursesExplorer", require( "./components/learn/CoursesExplorer" ).default );
Vue.component( "CourseDetail", require( "./components/learn/CourseDetail" ).default );
Vue.component( "PastEvents", require( "./components/events/PastEvents" ).default );
Vue.component( "Speakers", require( "./components/events/Speakers" ).default );
Vue.component( "Slides", require( "./components/events/Slides" ).default );
Vue.component( "UpcomingEvents", require( "./components/events/UpcomingEvents" ).default );

Vue.config.ignoredElements = [ /^cf/ ];
Vue.filter( "truncate", function( text, length, suffix ) {
	if ( text.length > length ) {
		return text.substring( 0, length ) + suffix;
	} else {
		return text;
	}
} );

export default new Vue( { el: "#app" } );
$( document ).ready( function(){
	var adminBar = document.getElementById( "cb-admin-bar" );
	if ( adminBar ) {
		document.getElementById( "app" ).classList.add( "has-admin-bar" );
	}
	// Get the header
	var header = document.getElementById( "header-main" );
	if ( header ){
		// Get the offset position of the header
		var stickyOffset = header.offsetTop;
		// When the user scrolls the page, execute myFunction
		window.onscroll = function() { stickHeader( header, stickyOffset ); };
	}

	// activate tooltips
	activateTooltips();

	// scroll spys
	$( "#navbar-main a" ).on( "click", function(){
		activateScroll( $( this ).attr( "href" ), false );
	} );
	$( "a[data-spy='true']" ).on( "click", function( event ){
		activateScroll( $( this ).attr( "href" ), true );
		event.preventDefault();
	} );

	// show more text
	$( ".show-more" ).click( function() {
		$( this ).prev().toggleClass( "restrict-height" );
		$( this ).html( $( this ).text().trim() === "less" ? "<i class='fa fa-arrow-down'></i> more" : "<i class='fa fa-arrow-up'></i> less" );

	} );

	// Add avatar classes as nice circles!
	$( "img.gravatar" ).each( function( index ){
		$( this ).addClass( "img-circle" );
	} );

	// Download Software Contact Activator
	$( ".downloadContact" ).on( "click", function( event ){
		// prevent default
		event.preventDefault();
		// Verify if we have contact already, if so, just log
		if ( Cookies.get( "ortus-download-contact" ) !== undefined ){
			return saveContactHistory(
				$( this ).data( "product" ),
				$( this ).prop( "href" ),
				Cookies.get( "ortus-download-contact" )
			);
		}
		// else we need info, collect it
		var downloadForm = $( "#downloadForm" );
		// store the target URL and Product Name
		downloadForm.find( "#targetURL" )
			.val( $( this ).prop( "href" ) );
		downloadForm.find( "#product" )
			.val( $( this ).data( "product" ) );
		// Show modal
		$( "#downloadModal" ).modal( "show" );
	} );

	// Download Software form Submit Event
	$( "#downloadForm" ).submit( function( event ){
		event.preventDefault();
		downloadContactForm();
	} );

	// Blog Expansions
	$( "#btn-sidebar-toggle" ).click( function( event ){
		event.preventDefault();
		toggleSidebar( "col-blog-sidebar", "col-blog-main" );
	} );

	// general modal
	$( "#generalModal" ).on( "show.bs.modal", function( event ) {
		var button = $( event.relatedTarget );
		var title = button.data( "title" ); // Modal title
		var contentURL = button.data( "contenturl" ); // URL to be loaded in modal body
		var contentType = button.data( "contenttype" ); // content type ( options: contact )

		var modal = $( this );
  		modal.find( ".modal-title" ).text( title );
  		modal.find( ".modal-body" ).load( contentURL , function() {
		  // If modal's content is a contact form
		  if ( contentType !== undefined && contentType !== null ) {
		  	 if ( contentType === "contact" ){
		  	 	$( this ).find( $( "input[name='subject']" ) ).val( title );
		  	 }
		  }

		} );
	} );

	// hidden general modal
	$( "#generalModal" ).on( "hidden.bs.modal", function( e ) {
  		var modal = $( this );
  		// Clear title and body
  		modal.find( ".modal-title" ).text( "Title" );
  		modal.find( ".modal-body" ).text( "..." );
	} );

	if ( $( "#toc" ).attr( "id" ) !== undefined ){
		// hide toc title, not needed
		$( "#toctitle" ).hide();
		// copy over the toc into the toc box
		$( "#sidebar-toc-body" ).html( $( "#toc" ) );
		// add moto scrolling
		$( "#toc a" ).on( "click", function( event ){
			activateScroll( $( this ).attr( "href" ), true );
			event.preventDefault();
		} );
	} else {
		// don't show panel, as there is no table of contents
		$( "#sidebar-toc" ).hide();
	}

	//Show and Hide explain field in contact page if user selected OTHER option of source field
	$( "#source" ).on( "change", function() {
		if ( $( this ).val().trim() === "Other" ) {
			$( "#explain" ).attr( "type", "text" );
		} else {
			$( "#explain" ).attr( "type", "hidden" );
			$( "#explain" ).val( "" );
		}
	} );

	//Show and Hide explain field in contact page if user selected OTHER option of interested field
	$( "#fieldAbout" ).on( "change", function() {
		if ( $( this ).val().trim() === "Other" ) {
			$( "#explainother" ).attr( "type", "text" );
		} else {
			$( "#explainother" ).attr( "type", "hidden" );
			$( "#explainother" ).val( "" );
		}
	} );


	$( "#btn-solo" ).on( "click", function(){
		showFeatures ( $( "#btn-solo" ).attr( "edition" ).trim() );
	} );

	$( "#btn-pro" ).on( "click", function(){
		showFeatures ( $( "#btn-pro" ).attr( "edition" ).trim() );
	} );

	$( "#btn-business" ).on( "click", function(){
		showFeatures ( $( "#btn-business" ).attr( "edition" ).trim() );
	} );

	$( "#btn-enterprise" ).on( "click", function(){
		showFeatures ( $( "#btn-enterprise" ).attr( "edition" ).trim() );
	} );

	$( "#go-top" ).on( "click", function(){
		$( "body, html" ).animate( { scrollTop: "0px" }, 300 );
	} );

	$( "#btnSearch" ).on( "click", function(){
		if ( !$( "#universalSearch" ).hasClass( "in" ) ){
			setTimeout( function(){
				$( "#universalSearchField" ).focus();
			}, 150 );
		} else {
			$( "#universalSearchField" ).blur();
		}
	} );

	window.addEventListener( "scroll", scrollEffects );
	window.addEventListener( "scroll", scrollMiniContactUs );

} );

/**
 * @param  string AccordinID
 */

window.manageAccordion = function( idAccordion ) {
	var accordion = document.getElementById( idAccordion );
	if ( accordion ){
		if ( accordion.classList.contains( "plan-is-open" ) ){
			accordion.classList.remove( "plan-is-open" );
		} else {
			accordion.classList.add( "plan-is-open" );
		}
	}
};

window.manageFqaAccordion = function( idFqaAccordion ) {
	var fqaAccordion = document.getElementById( idFqaAccordion );
	if ( fqaAccordion ){
		if ( fqaAccordion.classList.contains( "fqa-bg-blue" ) ){
			fqaAccordion.classList.remove( "fqa-bg-blue" );
			fqaAccordion.classList.remove( "text-dark-blue" );
		} else {
			fqaAccordion.classList.add( "fqa-bg-blue" );
			fqaAccordion.classList.add( "text-dark-blue" );
		}
	}
};

window.contentboxAccordion = function( idcontentboxAccordion ) {
	var contentboxAccordion = document.getElementById( idcontentboxAccordion );
	var textColor = contentboxAccordion.querySelector( ".title-id" );
	var icon = contentboxAccordion.querySelector( ".ico-id" );
	if ( contentboxAccordion ){
		if ( contentboxAccordion.classList.contains( "bg-instructor" ) ){
			contentboxAccordion.classList.remove( "bg-instructor" );
			contentboxAccordion.classList.add( "tw-bg-gray-100" );
			textColor.classList.remove( "tw-text-white" );
			textColor.classList.add( "tw-text-gray-400" );
			icon.classList.remove( "fa-minus" );
			icon.classList.add( "fa-plus" );
			icon.classList.remove( "tw-text-white" );
			icon.classList.add( "tw-text-gray-400" );


		} else {
			contentboxAccordion.classList.remove( "tw-bg-gray-100" );
			contentboxAccordion.classList.add( "bg-instructor" );
			textColor.classList.remove( "tw-text-gray-400" );
			textColor.classList.add( "tw-text-white" );
			icon.classList.remove( "fa-plus" );
			icon.classList.add( "fa-minus" );
			icon.classList.remove( "tw-text-gray-400" );
			icon.classList.add( "tw-text-white" );
		}
	}
};

window.readContentboxAccordion = function( idRead ) {
	var readContentboxAccordion = document.getElementById( idRead );
	if ( readContentboxAccordion ){
		if ( readContentboxAccordion.classList.contains( "readMore" ) ){
			readContentboxAccordion.innerHTML = "- Read Less";
			readContentboxAccordion.classList.remove( "readMore" );
		} else {
			readContentboxAccordion.innerHTML = "+ Read More";
			readContentboxAccordion.classList.add( "readMore" );
		}
	}
};

window.copyText = function( textId ) {
	if ( String ( textId ).includes( "logbox@be" ) ){
		navigator.clipboard.writeText( "install logbox@be" );
	} else if ( String ( textId ).includes( "logbox" ) ){
		navigator.clipboard.writeText( "install logbox" );
	} else if ( String ( textId ).includes( "Docker" ) ){
		navigator.clipboard.writeText( "docker pull ortussolutions/contentbox &&" + "\ndocker run -p 8080:8080 \ " + "\n-e express=true \ " + "\n-e install=true \ " + "\nortussolutions/contentbox" );
	} else {
		navigator.clipboard.writeText( "Install contentbox-cli" + "\nmkdir mysite --cd" + "\ncontentbox install-wizard" );
	}
	document.getElementById( textId ).setAttribute( "data-original-title", "copied to clipboard" ).tooltip( "show" );
};

function scrollEffects() {
	var scrollTop = document.documentElement.scrollTop;

	// Effect from aside section of blog detail page
	if ( typeof( document.getElementById( "aside-post" ) ) != "undefined" && document.getElementById( "aside-post" ) != null )
	{
		var aside   = document.getElementById( "aside-post" );
		var article = document.getElementById( "body-post" );

		if ( scrollTop > 250 && scrollTop <= ( article.clientHeight + 200 ) ) {
			aside.classList.remove( "tw-opacity-0" );
			aside.classList.add( "tw-opacity-100" );
		} else {
			aside.classList.remove( "tw-opacity-100" );
			aside.classList.add( "tw-opacity-0" );
		}
	}

	// Effect from button go to the top
	if ( typeof( document.getElementById( "go-top" ) ) != "undefined" && document.getElementById( "go-top" ) != null )
	{
		var btnGoTop  = document.getElementById( "go-top" );
		if ( scrollTop > 250 ) {
			btnGoTop.classList.remove( "tw-opacity-0" );
			btnGoTop.classList.add( "tw-opacity-100" );
		} else {
			btnGoTop.classList.remove( "tw-opacity-100" );
			btnGoTop.classList.add( "tw-opacity-0" );
		}
	}
}

function showFeatures( edition ) {

	if ( $( "#feature-"+edition ).hasClass( "hidden-xs" ) && $( "#feature-"+edition ).hasClass( "hidden-sm" )  ) {

		$( "#btn-"+edition ).text( "Hide all features" );
		$( "#feature-"+edition ).removeClass( "hidden-xs" );
		$( "#feature-"+edition ).removeClass( "hidden-sm" );

	} else {

		$( "#btn-"+edition ).text( "See all features" );
		$( "#feature-"+edition ).addClass( "hidden-xs" );
		$( "#feature-"+edition ).addClass( "hidden-sm" );
	}

	$( ".features" ).css( "margin-bottom", "10%" );
}

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickHeader( element, offset ) {
	if ( window.pageYOffset > offset ) {
		element.classList.remove( "obsolute" );
		element.classList.add( "sticky" );
	} else {
		element.classList.remove( "sticky" );
		element.classList.add( "obsolute" );
	}
}
/**
 * Activate navigation scroll for menu items
 * @param  string target the href of the target
 * @param  boolean spy    indicator if this is an inline anchor
 */
function activateScroll( target, spy ){
	if ( spy === false && target.charAt( 0 ) !== "#" ){ return; }

	// if nothing passed, just skip out
	if ( target.length <= 1 ){ return; }
	// animate
	$( "html, body" ).animate( { scrollTop: $( target ).offset().top - 125 }, 2000 );
	// slide back up on mobile.
	if ( $( "#ortus-family-collapse:visible" ) && $( "#ortus-nav-collapse:visible" ) ){
		$( "#ortus-navbar-toggle" ).click();
	}
}

/**
 * Activate Bootstrap tooltips
 */
function activateTooltips(){
	// Global Tool Tip Settings
	var toolTipSettings	= {
		 animation : "slide",
		 delay     : { show: 250, hide: 250 }
	};
	//Tooltip
	$( "[title]" ).tooltip( toolTipSettings );
}

/**
 * Relocation shorcuts
 * @param link
 * @returns {Boolean}
 */
function to( link ){
	window.location = link;
	return false;
}

/**
 * Toggle sidebar for print purposes
 * @param  string sidebarID The dom ID to collapse
 * @param  string contentID The dom ID of the content to keep
 */
function toggleSidebar( sidebarID, contentID ){
	var sidebarDisplay = $( "#" + sidebarID ).css( "display" );

	switch ( sidebarDisplay ){
	case "block" : {
		$( "#" + sidebarID ).fadeOut();
		$( "#" + contentID ).removeClass( "col-md-9" ).addClass( "col-md-12" );
		$( "#btn-sidebar-toggle" ).html( "<i class=\"fa fa-search-minus\"></i> Collapse" );
		break;
	}
	case "none" : {
		$( "#btn-sidebar-toggle" ).html( "<i class=\"fa fa-search-plus\"></i> Expand" );
		$( "#" + sidebarID ).fadeIn();
		$( "#" + contentID ).removeClass( "col-md-12" ).addClass( "col-md-9" );
		break;
	}
	}
}

/**
 * Save contact history
 * @param  {string} product   The product name
 * @param  {string} targetURL The target URL
 * @param  {string} contactID The contact ID
 */
function saveContactHistory( product, targetURL, contactID ){
	var historyForm = $( "#downloadHistoryForm" );
	// Post it
	$.post(
		historyForm.prop( "action" ),
		{
			"product"  	: product,
			"targetURL" : targetURL,
			"contactID" : contactID
		},
		function( data ){
			// verify errors
			if ( data.error ){
				Cookies.remove( "ortus-download-contact" );
			} else {
				// download the item now
				window.location = targetURL;
			}
		}
	);
}

/**
 * Download ortus software via contact form
 */
function downloadContactForm(){
	var downloadModal 	= $( "#downloadModal" );
	var downloadForm 	= $( "#downloadForm" );
	var targetURL		= downloadForm.find( "#targetURL" ).val();
	var btnDownload 	= downloadForm.find( "#downloadFormSubmit" );

	// Message
	btnDownload
		.html( "Please Wait..." )
		.prop( "disabled", "disabled" );
	// Post it
	$.post(
		downloadForm.prop( "action" ),
		downloadForm.serialize(),
		function( data ){
			// verify errors
			if ( data.error ){
				alert( data.messages );
			} else {
				// remember so only shown once:
				Cookies.set( "ortus-download-contact", data.data.contactID );
				// close modal and reset form
				downloadModal.modal( "hide" );
				downloadForm[ 0 ].reset();
				// download the item now
				window.location = targetURL;
			}
			// reset button
			btnDownload
				.html( "Download" )
				.prop( "disabled", false );
		}
	);

}

/**
 * Method to show/hide a mini contact us section in UX/UI page
 */
function scrollMiniContactUs(){
	if ( typeof( document.getElementById( "mini-contact-pop" ) ) != "undefined" &&
		document.getElementById( "mini-contact-pop" ) != null ){

		var scrollTop = document.documentElement.scrollTop;
		if ( $( window ).innerWidth() > 992 ){
			if ( scrollTop > 150 && scrollTop <= 300 ){
				setTimeout( showMiniContactUs(), 5000 );
			}
		} else {
			$( "#mini-contact-pop" ).removeClass( "mini-contact-hidden" );
		}
	}
}

function showMiniContactUs() {
	$( "#mini-contact-pop" ).removeClass( "mini-contact-hidden" ).addClass( "mini-contact-animation" );
}