<template>
	<transition :name="transition">
		<div
			v-show="active"
			class="tw-text-md lg:tw-text-xl tw-space-y-1 tw-absolute"
		>
			<slot />
			<p
				class="tw-font-bold tw-text-md tw-mt-3"
			>
				{{ title }}
			</p>
		</div>
	</transition>
</template>

<script>
export default {
	name  : "Slide",
	props : {
		// Receive the slide title
		title : {
			type    : String,
			default : ""
		},
		/*  Type of transition to pass each slide
			@values:
		*/
		transitionName : {
			type    : String,
			default : "",
			require : false
		},
	},
	data() {
		return {
			active     : false,  // Current state of slide
			transition : "",     // Transition class to do in sliders
		};
	},
	methods : {
		// Deactivate and hide the slide and
		// also activate the correct transition.
		hide( effectName ) {
			this.transition = this.transitionName != "" ? this.transitionName : effectName;
			this.active = false;
		},
		// Activate and show the slide and
		// also activate the correct transition.
		show( effectName ) {
			this.transition = this.transitionName != "" ? this.transitionName : effectName;
			this.active = true;
		},
	},
};
</script>