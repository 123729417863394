<template>
	<div>
		<div
			:id="carouselId"
			class="owl-carousel"
			:class="carouselClass"
			v-if="isLoading"
		>
			<div class="pic">
				Images loading...
			</div>
		</div>
		<div
			:id="carouselId"
			class="owl-carousel"
			:class="carouselClass"
			v-else
		>
			<div
				v-for="( item, itemIdx ) in carouselData"
				:key="carouselId + '-' + itemIdx"
				class="item"
			>
				<div
					class="pic"
					v-if="getValue( item, 'href' ) != ''"
				>
					<a :href="getValue( item, 'href' )">
						<img
							class="img-responsive"
							:src="getMediaPath( item )"
							:alt="getValue( item, 'alt' )"
						/>
					</a>
				</div>
				<div
					class="pic"
					v-else
				>
					<img
						class="img-responsive"
						:src="getMediaPath( item )"
						:alt="getValue( item, 'alt' )"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="application/javascript">
import Owl from "owl.carousel";

export default {
	props : {
		carouselId : { 				// unique carousel Id
			type     : String,
			required : true
		},
		carouselClass : {			// carousel class
            	type : String
		},
		mediaFolder : {				// media folder with images
            	type    : String,
            	default : ""
		},
		fileList : {					// file list ( array of file names )
            	type : Array
		},
		imageInfo : {				// images' info ( alt, href, name )
            	type : Object
		},
		itemsOnStage : {				// items on screen
            	type    : Number,
            	default : 4
		},
		loop : {
            	type    : Boolean,
            	default : false
		},
		margin : {					// right marging of each item
            	type    : Number,
            	default : 4
		},
		showNav : {					// show nav's prev and next buttons
            	type    : Boolean,
            	default : false
		},
		autoPlay : {
            	type    : Boolean,
            	default : false
		},
		navContainer : {
            	type    : String,
            	default : ""
		},

	},
	data() {
	        return {
	            isLoading    : false,
	            carouselData : this.fileList
	        };
	    },
	mounted() {
	        this.$nextTick( function(){
	        	this.installOwlCarousel();
	        } );
	    },
	methods : {
		getValue : function( fileName, key ){
			if ( Object.keys( this.imageInfo ).length === 0 && this.imageInfo.constructor === Object ){
				return "";
			} else {
				if ( this.imageInfo.hasOwnProperty( fileName ) && this.imageInfo[ fileName ].hasOwnProperty( key ) ){
					return this.imageInfo[ fileName ][ key ];
				} else {
					return "";
				}
			}
		},
		getMediaPath : function( fileName ){
			if ( this.mediaFolder != "" ){
				return "/__media/" + this.mediaFolder + "/" + fileName;
			} else {
				return "/__media/" + fileName;
			}

		},
	        installOwlCarousel : function(){
	        	$( "#" + this.carouselId ).owlCarousel(
	                {
	                    loop       : this.loop,
	                    items      : this.itemsOnStage,
	                    margin     : this.margin,
	                    responsive : {
					        0    : { items: 1 },
					        600  : { items: this.itemsOnStage - 1 },
					        1000 : { items: this.itemsOnStage }
					    },
				        responsiveRefreshRate : 200,
				        autoplay              : this.autoPlay,
				        autoplayHoverPause    : false,
				        nav                   : this.showNav,
				        navContainer          : this.navContainer
	                }
	            );
	        }
	    }

};
</script>