<template>
	<div>
		<template v-if="events.length">
			<div class="container">
				<section id="events">
					<div class="tw-mt-12 tw-max-w-lg tw-mx-auto tw-grid tw-gap-4 lg:tw-grid-cols-3 lg:tw-max-w-none">
						<article
							v-for="event in events"
							:key="event.contentID"
							class="featuredEntries tw-transition tw-duration-400 tw-ease-in-out tw-transform hover:tw--translate-y-1 hover:tw-scale-105 tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden"
						>
							<div class="tw-flex-shrink-0">
								<a :href="event.slug">
									<img
										v-if="event.featuredImageURL"
										:src="event.featuredImageURL"
										alt="feature-image"
										class="tw-h-48 tw-w-full tw-object-cover"
									>
								</a>
							</div>
							<div class="tw-flex-1 tw-bg-white tw-p-6 tw-flex tw-flex-col tw-justify-between">
								<div class="tw-flex-1">
									<div>
										<small>
											{{ getCustomField( event, 'eventDates' ) }}<br>
											{{ getCustomField( event, 'location' ) }}
										</small>
									</div>
									<div
										v-if="getCustomField( event, 'category' )"
										class="label label-success"
									>
										{{ getCustomField( event, 'category' ) }}
									</div>
									<h2 class="tw-text-lg tw-font-semibold">
										<a
											:href="event.slug"
											class="tw-block tw-mt-2 tw-text-gray-900 tw-border tw-border-transparent focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-none focus:tw-border-transparent"
										>
											{{ event.title }}
										</a>
									</h2>
									<div class="tw-mt-3 tw-mb-10 tw-text-sm tw-leading-snug excerpt">
										<p>
											{{ getCustomField( event, 'subtitle' ) }}
										</p>
									</div>
									<div
										v-if="getCustomField( event, 'registrationLink' )"
										class="text-center margintop25"
									>
										<a
											:href="getCustomField( event, 'registrationLink' )"
											class="btn btn-info"
										>
											Register
										</a>
									</div>
								</div>
							</div>
						</article>
					</div>
				</section>
			</div>
		</template>
		<template v-else>
			<div class="tw-text-center">
				<p class="tw-text-gray-700 tw-text-3xl">
					<i
						class="far fa-frown"
						aria-hidden="true"
					/>
					No Upcoming Events
				</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props : {
		events : {
			type     : Array,
			required : true
		}
	},
	methods : {
		/**
		* Get customfield from an object, returns empty string when it doesn't exists
		* @param {Object} obj The object containing the customfield
		* @param {String} fieldName The name of the custom field
		*/
		getCustomField( obj, fieldName ) {
			let field = obj.customFields.find( ( field ) => field.key === fieldName );
			return field != undefined ? field.value : "";
		}
	}
};
</script>