<template>
	<div v-if="isMain">
		<div class="row bg-courses-head tw-bg-no-repeat">
			<div class="container tw-mx-auto tw-mt-8 md:tw-mt-10 lg:tw-mt-20">
				<div class="tw-text-white tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center tw-space-x-3 tw-space-y-3 md:tw-space-y-0">
					<img
						src="/__media/2022/learn/courses/logo-ortus-courses-hor.svg"
						alt="background Ortus Courses"
						class="tw-w-96"
					>
					<p class="tw-text-xl tw-text-center md:tw-text-left lg:tw-px-20">
						Our courses catalog can be delivered <span class="tw-font-bold">on-site</span> or
						<span class="tw-font-bold">online</span> around the world.
					</p>
				</div>
			</div>
		</div>

		<training-quotes :testimonials="testimonials" />

		<div class="row tw-pt-20">
			<div class="container">
				<div class="col-sm-12 col-md-3 lg:tw-pt-4">
					<p class="tw-font-bold tw-text-md tw-text-gray-700">
						Filter by
					</p>
					<div>
						<button
							type="button"
							data-toggle="collapse"
							data-target="#productFilterSection"
							aria-expanded="true"
							aria-controls="productFilterSection"
							class="tw-flex tw-justify-between tw-items-center tw-bg-transparent hover:tw-bg-gray-300 hover:tw-bg-opacity-25 tw-text-blue-400 tw-p-2 hover:tw-border hover:tw-border-gray-500 hover:tw-text-blue-600 tw-w-full lg:tw-w-52"
						>
							<span>
								Products
							</span>
							<i
								aria-hidden="true"
								class="fas fa-caret-down"
							/>
						</button>
						<div
							id="productFilterSection"
							class="collapse in"
							aria-expanded="true"
						>
							<div class="tw-py-3 tw-pl-5 tw-space-y-1">
								<div
									v-for="( category, index ) in filteredCategory"
									:key="category"
									class="tw-flex tw-justify-start tw-items-center tw-space-x-3"
								>
									<input
										:aria-describedby="category"
										v-model="arrayProducts"
										:id="'products_' + index"
										:value="category"
										name="products[]"
										type="checkbox"
										@change="updateData"
										class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-500"
									/>
									<span
										class="tw-font-medium tw-text-gray-700 tw-mb-0"
										v-text="category"
									/>
								</div>
							</div>
						</div>
						<button
							type="button"
							data-toggle="collapse"
							data-target="#formatFilterSection"
							aria-expanded="true"
							aria-controls="formatFilterSection"
							class="tw-flex tw-justify-between tw-items-center tw-bg-transparent hover:tw-bg-gray-300 hover:tw-bg-opacity-25 tw-text-blue-400 tw-p-2 hover:tw-border hover:tw-border-gray-500 hover:tw-text-blue-600 tw-w-full lg:tw-w-52"
						>
							<span>
								Format
							</span>
							<i
								aria-hidden="true"
								class="fas fa-caret-down"
							/>
						</button>
						<div
							id="formatFilterSection"
							class="collapse"
							aria-expanded="true"
						>
							<div class="tw-py-3 tw-pl-5 tw-space-y-1">
								<div class="tw-relative tw-flex tw-flex-col tw-items-start tw-space-y-1">
									<div class="tw-flex tw-justify-start tw-items-center tw-space-x-3">
										<input
											v-model="arrayFormat"
											id="onsite"
											aria-describedby="onSite-description"
											name="format[]"
											value="onsite"
											type="checkbox"
											@change="updateData"
											class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-500"
										/>
										<span
											class="tw-font-medium tw-text-gray-700 tw-mb-0"
										>
											On Site
										</span>
									</div>
									<div class="tw-flex tw-justify-start tw-items-center tw-space-x-3">
										<input
											v-model="arrayFormat"
											id="virtuallive"
											aria-describedby="onSite-description"
											name="format[]"
											value="virtuallive"
											type="checkbox"
											@change="updateData"
											class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-500"
										/>
										<span
											class="tw-font-medium tw-text-gray-700 tw-mb-0"
										>
											Virtual Live
										</span>
									</div>
								</div>
							</div>
						</div>
						<button
							type="button"
							data-toggle="collapse"
							data-target="#DurationFilterSection"
							aria-expanded="true"
							aria-controls="DurationFilterSection"
							class="tw-flex tw-justify-between tw-items-center tw-bg-transparent hover:tw-bg-gray-300 hover:tw-bg-opacity-25 tw-text-blue-400 tw-p-2 hover:tw-border hover:tw-border-gray-500 hover:tw-text-blue-600 tw-w-full lg:tw-w-52"
						>
							<span>
								Duration
							</span>
							<i
								aria-hidden="true"
								class="fas fa-caret-down"
							/>
						</button>
						<div
							id="DurationFilterSection"
							class="collapse"
							aria-expanded="true"
						>
							<div class="tw-py-3 tw-pl-5 tw-space-y-1">
								<div
									v-for="(duration, index) in filteredDuration"
									:key="duration"
									class="tw-flex tw-justify-start tw-items-center tw-space-x-3"
								>
									<input
										:aria-describedby="duration"
										v-model="arrayDuration"
										:id="'duration_' + index"
										:value="duration.replace( ' ','' ).toLowerCase().trim()"
										name="duration[]"
										type="checkbox"
										@change="updateData"
										class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-500"
									/>
									<span
										class="tw-font-medium tw-text-gray-700 tw-mb-0"
										v-text="duration + ' Hours'"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-9 tw-mt-5 md:tw-mt-0">
					<!-- Filters Section -->
					<div class="tw-mb-6">
						<div class="tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-between tw-items-center tw-space-y-3 md:tw-space-y-0 tw-pb-6">
							<select
								v-model="selectedCategory"
								@change="updateData"
								class="tw-w-full md:tw-w-1/4 tw-border-0 tw-border-b tw-border-fuchsia-600 focus:tw-outline-none focus:tw-ring-transparent tw-text-gray-500 tw-font-bold"
							>
								<option
									value=""
									class="tw-font-bold"
								>
									Product
								</option>
								<option
									v-for="category in filteredCategory"
									:value="category"
									:key="category"
								>
									{{ category }}
								</option>
							</select>

							<select
								v-model="selectedLevel"
								@change="updateData"
								class="tw-w-full md:tw-w-1/4 tw-border-0 tw-border-b tw-border-fuchsia-600 focus:tw-outline-none focus:tw-ring-transparent tw-text-gray-500 tw-font-bold"
							>
								<option
									value=""
									class="tw-font-bold"
								>
									Level
								</option>
								<option
									v-for="location in filteredLevel"
									:value="location"
									:key="location"
								>
									{{ location }}
								</option>
							</select>

							<select
								v-model="selectedFormat"
								@change="updateData"
								class="tw-w-full md:tw-w-1/4 tw-border-0 tw-border-b tw-border-fuchsia-600 focus:tw-outline-none focus:tw-ring-transparent tw-text-gray-500 tw-font-bold"
							>
								<option
									value=""
									class="tw-font-bold"
								>
									Format
								</option>
								<option
									value="onSite"
								>
									On Site
								</option>
								<option
									value="virtuallive"
								>
									Virtual Live
								</option>
							</select>

							<button
								@click="resetFilters"
								class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded-lg tw-py-3 tw-px-5 tw-text-center tw-w-full md:tw-w-24"
								title="Resert Filters"
							>
								<i
									class="fas fa-sync tw-text-xl"
									aria-hidden="true"
								/>
							</button>
						</div>
					</div>

					<div
						v-if="this.paginatedData.length"
						class="tw-flex tw-flex-col tw-space-y-3 tw-justify-center tw-items-center"
					>
						<a
							v-for="course in paginatedData"
							:key="course.slug"
							@click.prevent="seeMore( course )"
							class="course-card tw-flex tw-border tw-border-gray-300 tw-bg-white hover:tw-bg-gray-800 tw-rounded-md tw-cursor-pointer tw-p-0.5"
						>
							<div class="card-logo tw-flex-none tw-h-28 md:tw-h-32 tw-w-28 md:tw-w-32 tw-bg-gray-100 tw-rounded-md tw-p-2">
								<div class="tw-flex tw-justify-center tw-items-center tw-h-full">
									<img
										src="/__media/2022/learn/overview/logo-ortus-cfcasts.svg"
										alt="cfcasts"
										class="tw-w-36"
									>
								</div>
							</div>
							<div class="card-body tw-w-28 sm:tw-w-32 md:tw-w-full tw-text-gray-800 tw-flex-grow tw-h-28 md:tw-h-32 tw-p-2 tw-overflow-ellipsis tw-overflow-hidden tw-text-sm">
								<p
									class="tw-text-base md:tw-text-lg tw-font-bold tw-m-0"
								>
									{{ course.title }}
								</p>
								<p class="tw-hidden md:tw-block tw-text-base tw-whitespace-normal tw-mt-1">
									{{ course.description | truncate( 100, '...' ) }}
									<span class="tw-no-underline tw-text-base tw-font-bold tw-text-blue-500">
										See more
									</span>
								</p>
								<div class="tw-hidden md:tw-flex tw-flex-col md:tw-flex-row tw-justify-start tw-items-start md:tw-items-center md:tw-space-x-3">
									<p class="tw-m-0">
										{{ getCustomFieldValue( course, "days" ) }} days |
										{{ getCustomFieldValue( course, "hours" ) }} hours
									</p>
									<p class="tw-m-0">
										{{ getCustomFieldValue( course, "level" ) }}
									</p>
									<p class="tw-hidden md:tw-block tw-m-0">
										Min. Student: {{ getCustomFieldValue( course, "minStudents" ) }}
									</p>
									<p class="tw-hidden md:tw-block tw-m-0">
										up to {{ getCustomFieldValue( course, "maxStudents" ) }} Students
									</p>
								</div>
							</div>
							<div class="card-price tw-flex-none tw-h-28 md:tw-h-32 tw-w-28 md:tw-w-32 tw-rounded-md">
								<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-space-y-1">
									<div class="tw-bg-white tw-text-gray-800 tw-border tw-border-gray-300 tw-rounded-md tw-text-center">
										<p class="tw-mb-0 tw-font-bold">
											${{ getCustomFieldValue( course, "price" ) }}
										</p>
										<p class="tw-mb-1 tw-text-sm">
											Per Student
										</p>
										<a
											href="##"
											style="margin: 0px"
											data-contenttype="contact"
											data-contenturl="/modals/modal-contact"
											data-target="#generalModal"
											:data-title="course.title"
											data-toggle="modal"
											class="btn btn-primary btn-courses-book"
										>
											BOOK
										</a>
									</div>
									<a
										v-if="getCustomFieldValue( course, 'cfcastsURL' ).length"
										:href="getCustomFieldValue( course, 'cfcastsURL' )"
										target="_blank"
										rel="noopener noreferrer"
										class="tw-hidden md:tw-block tw-bg-white tw-rounded-md tw-w-28 text-center"
									>
										<img
											src="/__media/2022/learn/overview/logo-ortus-cfcasts.svg"
											alt="cfcasts"
											class="tw-w-16"
										>
									</a>
								</div>
							</div>
						</a>
					</div>
					<div
						v-else
						class="tw-text-center"
					>
						<p class="tw-text-gray-600 tw-text-lg tw-font-bold tw-rounded tw-bg-gray-100 tw-p-3">
							No data found
							<i
								class="fas fa-search tw-mr-2"
								aria-hidden="true"
							/>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- Pagination Section -->
		<div class="row tw-py-20">
			<div class="container">
				<div class="col-sm-12 col-md-3" />
				<div class="col-sm-12 col-md-9 tw-text-center">
					<nav
						class="tw-isolate tw-inline-flex tw-space-x-0 tw-rounded-md tw-shadow-sm"
						aria-label="Pagination"
					>
						<button
							@click="previousPage()"
							:disabled="this.currentePage <= 1"
							class="tw-relative tw-inline-flex tw-items-center tw-rounded-l-md tw-border tw-border-gray-300 tw-bg-white tw-p-3 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50 focus:tw-z-20"
							:style="this.currentePage <= 1 ? {'cursor': 'not-allowed'} : {}"
						>
							<span class="tw-sr-only">Previous</span>
							<i
								class="fas fa-chevron-left"
								aria-hidden="true"
							/>
						</button>

						<a
							v-for="pagina in totalPages()"
							:key="pagina"
							v-text="pagina"
							@click.prevent="getDataByPage( pagina )"
							:aria-current="isActive( pagina ) ? 'page' : ''"
							class="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer tw-border tw-px-4 tw-py-2 tw-text-sm tw-font-medium focus:tw-z-20"
							:class="isActive( pagina ) ? 'z-10 tw-border-indigo-500 tw-bg-indigo-50 tw-text-indigo-600' : 'tw-border-gray-300 tw-bg-white tw-text-gray-500'"
						/>
						<button
							@click="nextPage()"
							:disabled="this.currentePage >= this.totalPages()"
							class="tw-relative tw-inline-flex tw-items-center tw-rounded-r-md tw-border tw-border-gray-300 tw-bg-white tw-p-3 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50 focus:tw-z-20 tw-no-underline focus:tw-ring-0 focus:tw-outline-none"
							:style="this.currentePage >= this.totalPages() ? {'cursor': 'not-allowed'} : {}"
						>
							<span class="tw-sr-only">Next</span>
							<i
								class="fas fa-chevron-right"
								aria-hidden="true"
							/>
						</button>
					</nav>
				</div>
			</div>
		</div>
	</div>
	<course-detail
		v-else
		:course="courseSelected"
	/>
</template>
<script>
import CourseDetail from "./CourseDetail.vue";
import TrainingQuotes from "./TrainingQuotes.vue";
export default {
	components : { CourseDetail, TrainingQuotes },
	props      : {
		courses : {
			type     : Array,
			required : true
		},
		testimonials : {
			type     : Array,
			required : true
		},
	},
	data() {
		return {
			isMain         	 : true,
			courseSelected 	 : [],
			selectedCategory : "",
			selectedLevel    : "",
			selectedFormat   : "",
			arrayProducts    : [],
			arrayDuration    : [],
			arrayFormat      : [],
			currentePage     : 1,
			elementsByPage 	 : 5,
			paginatedData    : [],
			filteredData     : []
		};
	},
	computed : {
		filteredCategory(){
			return this.courses.reduce( ( accumulator, items ) => {
				items.categories.forEach( category => {
					if ( !accumulator.find( x => x == category ) ){
						accumulator.push( category );
					}
				} );
				return accumulator;
			}, [] );
		},
		filteredDuration(){
			return this.courses.reduce( ( accumulator, items ) => {
				items.customFields.filter( k =>  k.key == "hours"  )
					.forEach( customField => {
						if ( !accumulator.find( x => x == customField.value.trim() ) ){
							accumulator.push( customField.value.trim() );
						}
					} );
				return accumulator;
			}, [] );
		},
		filteredLevel(){
			return this.courses.reduce( ( accumulator, items ) => {
				items.customFields.filter( k =>  k.key == "level"  )
					.forEach( customField => {
						customField.value.split( "," ).forEach( level => {
							if ( !accumulator.find( x => x == level.trim() ) ){
								accumulator.push( level.trim() );
							}
						} );
					} );
				return accumulator;
			}, [] );
		}
	},
	methods : {
		updateData(){
			this.filteredData = this.courses
				.filter(  item => {
					if ( this.selectedCategory.length || this.arrayProducts.length ){
						return item.categories.includes( this.selectedCategory ) || item.categories.some( c => this.arrayProducts.includes( c ) );
					}
					return true;
				} )
				.filter(  item => {
					if ( this.selectedLevel.length ){
						return this.getCustomFieldValue( item, "level" ).includes( this.selectedLevel );
					}
					return true;
				} )
				.filter(  item => {
					if ( this.selectedFormat.length || this.arrayFormat.length ){
						var itemFormat = this.getCustomFieldValue( item, "format" ).replace( " ", "" ).toLowerCase().trim();
						return itemFormat == this.selectedFormat.toLowerCase().trim() || this.arrayFormat.some( c => c == itemFormat );
					}
					return true;
				} )
				.filter(  item => {
					if ( this.arrayDuration.length ){
						return this.arrayDuration.includes( this.getCustomFieldValue( item, "hours" ).trim() );
					}
					return true;
				} );

			this.getDataByPage( 1 );
		},
		// Pagination Methods
		getDataByPage( numberPage ){
			this.currentePage = numberPage;
			this.filteredData = this.filteredData.length ? this.filteredData : this.courses;

			this.paginatedData = [];
			let start = ( numberPage * this.elementsByPage ) - this.elementsByPage;
			let end   = ( numberPage * this.elementsByPage );

			this.paginatedData = this.filteredData.slice( start, end );
		},
		totalPages(){
			return Math.ceil( this.filteredData.length / this.elementsByPage );
		},
		previousPage(){
			if ( this.currentePage > 1 ){
				this.currentePage--;
			}
			this.getDataByPage( this.currentePage );
		},
		nextPage(){
			if ( this.currentePage < this.totalPages() ){
				this.currentePage++;
			}
			this.getDataByPage( this.currentePage );
		},
		isActive( numberPage ){
			return numberPage == this.currentePage ? true : false;
		},
		// Utils Methods
		resetFilters(){
			this.selectedCategory = "";
			this.selectedLevel    = "";
			this.selectedFormat   = "";
			this.arrayProducts    = [];
			this.arrayDuration    = [];
			this.arrayFormat      = [];
			this.updateData();
		},
		getCustomFieldValue( obj, key ){
			return Object.values( obj.customFields ).reduce( ( accumulator, customField ) => {
				if ( customField.key == key ) {
					accumulator = customField.value;
				}
				return accumulator;
			}, "" );
		},
		seeMore( course ){
			this.courseSelected = course;
			this.isMain = !this.isMain;
		}
	},
	mounted(){
		this.getDataByPage( 1 );
	}
};
</script>