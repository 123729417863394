<template>
	<div>
		<div class="row webinar-head">
			<div class="container text-center tw-h-full tw-mt-10">
				<h1 class="tw-text-white tw-text-4xl xl:tw-text-6xl tw-font-bold">
					FREE WEBINAR SERIES
				</h1>
				<p class="textOrtus tw-text-2xl tw-px-10 lg:tw-px-64">
					Check our webinar series on our latest innovations
					and new product releases.
				</p>
			</div>
		</div>

		<!--- Upcoming Webinars --->
		<div class="row tw-mt-20">
			<div class="container">
				<h2 class="tw-text-gray-800 tw-text-3xl">
					UPCOMING
				</h2>
				<webinar-upcoming :webinars="upcomingWebinars" />
			</div>
		</div>

		<!--- Last Webinars --->
		<div class="row tw-my-28">
			<div class="container">
				<div class="tw-bg-gray-100 tw-rounded-lg tw-p-5">
					<div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-space-y-3 md:tw-space-y-0 tw-flex-wrap">
						<div class="tw-col-span-2">
							<p class="tw-text-gray-700 tw-font-bold tw-text-3xl">
								OUR LIBRARY
							</p>
						</div>
						<div>
							<select
								v-model="selectedYear"
								class="tw-text-gray-700 tw-block tw-w-48 tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
							>
								<option
									value=""
								>
									Year
								</option>
								<option
									v-for="year in dropdownYear"
									:key="year"
								>
									{{ year }}
								</option>
							</select>
						</div>
						<div>
							<select
								v-model="selectedAuthor"
								class="tw-text-gray-700 tw-block tw-w-auto tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
							>
								<option
									value=""
								>
									Authors
								</option>
								<option
									v-for="author in dropdownAuthors"
									:key="author"
								>
									{{ author }}
								</option>
							</select>
						</div>
						<div>
							<input
								v-model="webinarTitle"
								type="text"
								name=""
								id=""
								placeholder="Title"
								class="control-webinar-filter focus:tw-ring-0 tw-text-gray-600 tw-font-bold"
							>
						</div>
						<div class="tw-text-right">
							<button
								@click="cleanUp"
								class="hover:tw-bg-gray-300 hover:tw-text-white tw-text-gray-400 tw-rounded-lg tw-py-3 tw-px-5 tw-text-center"
							>
								<i
									class="fas fa-sync tw-text-xl"
									aria-hidden="true"
								/>
							</button>
						</div>
					</div>

					<div class="tw-flex tw-justify-center tw-items-center tw-space-x-2 tw-bg-white tw-rounded-lg tw-mt-5 tw-p-3">
						<p class="tw-text-lg tw-text-gray-800">
							All our webinars are avilable on
						</p>
						<a
							href="https://cfcasts.com/series"
							target="_blank"
						>
							<img
								:src="themeRoot+'/resources/assets/images/events/webinar/logo-cfcasts.svg'"
								alt="Logo Cfcasts"
							>
						</a>
					</div>
				</div>

				<div
					v-if="dataFiltered.length"
					class="tw-flex tw-flex-row tw-flex-wrap tw-mt-20 tw-justify-center"
					:class="dataFiltered.length > 2 ? 'md:tw-justify-between' : 'tw-justify-start tw-space-x-20'"
				>
					<webinar-card
						v-for="(webinar, index) in dataFiltered"
						:key="index"
						:webinar="webinar"
						class="flex-basic-30"
					/>
				</div>
				<div
					v-else
					class="tw-text-center tw-mt-20"
				>
					<p class="tw-text-gray-700 tw-text-3xl">
						<i
							class="far fa-frown"
							aria-hidden="true"
						/>
						No webinars matches
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WebinarCard from "./WebinarCard.vue";

export default {
	components : { WebinarCard },
	props      : {
		webinars : {
			type     : Array,
			required : true
		},
		themeRoot : {
			type    : String,
			default : ""
		}
	},
	data() {
		return {
			selectedYear   : "",
			selectedAuthor : "",
			webinarTitle   : ""
		};
	},
	methods : {
		cleanUp(){
			this.selectedYear = "";
			this.selectedAuthor = "";
			this.webinarTitle = "";
		}
	},
	computed : {
		upcomingWebinars(){
			return this.webinars.filter( item => {
				return item.isUpcoming == true;
			} ).sort( function( a,b ) {
				return new Date( a.webinarDate ) - new Date( b.webinarDate );
			} );
		},
		// Method to fill the year dropdown with the webinars' years
		dropdownYear(){
			return this.webinars.map( ( webinar ) => ( new Date( webinar.webinarDate ) ).getFullYear() )
				.filter( function( item, pos, self ) {
					return self.indexOf( item ) == pos;
				} );
		},
		// Method to fill the author dropdown with the author's name
		dropdownAuthors(){
			return this.webinars.filter( webinar => webinar.isUpcoming == false )
				.map( item => item.avatars ).
				reduce( ( acc, value ) => {
					acc.push( value[0].authorName.trim() );
					return acc;

				}, [] ).filter( function( item, pos, self ) {
					return self.indexOf( item ) == pos;
				} );
		},
		// Get the webinars filtered by title, month, or author's name
		dataFiltered(){
			return this.webinars
				.filter(  item => {
					return item.isUpcoming == false;
				} )
				.filter(  item => {
					if ( this.webinarTitle.length ){
						return item.title.toLowerCase().trim().includes( this.webinarTitle.toLowerCase().trim() );
					}
					return true;
				} )
				.filter( item => {
					if ( this.selectedYear.length ){
						return ( new Date( item.webinarDate ) ).getFullYear() == Number.parseInt( this.selectedYear );
					}
					return true;
				} )
				.filter(  item => {
					if ( this.selectedAuthor.length ){
						return item.avatars.find(
							element => element.authorName.toLowerCase().trim().includes( this.selectedAuthor.toLowerCase().trim() )
						);
					}
					return true;
				} ) ;
		}
	}
};
</script>