<template>
	<div
		v-if="testimonials"
		class="container tw-my-10"
	>
		<div class="tw-border tw-border-gray-300 tw-shadow-md tw-rounded-md tw-h-auto md:tw-h-60 tw-p-3">
			<div class="tw-flex tw-h-full">
				<div class="tw-flex-none tw-h-full tw-w-12 text-left tw-my-auto">
					<button
						type="button"
						class="tw-h-full tw-w-full hover:tw-bg-gray-100 tw-text-gray-300 hover:tw-text-gray-400"
						@click="prev( ( dataIndex - 1 ) )"
					>
						<i
							class="fa fa-chevron-left tw-text-2xl"
							aria-label="Previous"
						/>
					</button>
				</div>
				<div class="tw-flex-grow tw-h-full">
					<div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center md:tw-justify-between tw-items-center tw-h-5/6 tw-p-2 tw-space-y-2 md:tw-space-y-0 ">
						<div class="tw-w-full md:tw-w-4/5 md:tw-px-3">
							<p
								v-for="( title, index ) in getTitles"
								:key="title"
								class="tw-m-0 tw-text-blue-800"
								:class="index == 0 ? 'tw-font-bold tw-text-2xl' : 'tw-text-xl tw-ml-5'"
							>
								{{ title }}
							</p>
							<p class="tw-text-gray-800 tw-m-0 tw-mt-1">
								{{ testimonialSelected.content }}
							</p>
						</div>
						<div class="tw-w-full md:tw-w-1/5 tw-text-gray-800">
							<p class="tw-font-bold tw-text-lg tw-m-0">
								-{{ testimonialSelected.title }}
							</p>
							<p class="tw-m-0 tw-text-base tw-mt-1">
								{{ testimonialSelected.description }}
							</p>
						</div>
					</div>
					<div class="text-center tw-space-x-1">
						<button
							v-for="(punto, index) in totalData"
							:key="index"
							@click="moveTest( index )"
							:class="index == dataIndex ? 'tw-bg-blue-800 tw-border-blue-800': 'tw-border-blue-700'"
							class="tw-m-0 tw-rounded-full tw-border tw-h-4 tw-w-4 hover:tw-bg-blue-700"
						>
                            &nbsp;
						</button>
					</div>
				</div>
				<div class="tw-flex-none tw-h-full tw-w-12 text-right tw-my-auto">
					<button
						type="button"
						class="tw-h-full tw-w-full hover:tw-bg-gray-100 tw-text-gray-300 hover:tw-text-gray-400"
						@click="next( ( dataIndex + 1 ) )"
					>
						<i
							class="fa fa-chevron-right tw-text-2xl"
							aria-label="Next"
						/>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props : {
		testimonials : {
			type     : Array,
			required : true
		}
	},
	data() {
		return {
			testimonialSelected : this.testimonials[0],
			totalData           : this.testimonials.length,
			dataIndex           : 0
		};
	},
	computed : {
		getTitles(){
			return this.getCustomFieldValue( this.testimonialSelected, "courses" ).split( "," );
		}
	},
	methods : {
		moveTest( index ){
			this.dataIndex = index;
			this.testimonialSelected = this.testimonials[ index ];
		},
		prev( index ){
			this.dataIndex = index < 0 ? ( this.totalData - 1 ) : index;
			this.testimonialSelected = this.testimonials[ this.dataIndex ];
		},
		next( index ){
			this.dataIndex = index > ( this.totalData - 1 ) ? 0 : index;
			this.testimonialSelected = this.testimonials[ this.dataIndex ];

		},
		getCustomFieldValue( obj, key ){
			if ( obj && obj.customFields.length ){
				return Object.values( obj.customFields ).reduce( ( accumulator, customField ) => {
					if ( customField.key == key ) {
						accumulator = customField.value;
					}
					return accumulator;
				}, "" );
			}
			return "";
		},
	}
};
</script>