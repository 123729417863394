import Axios from "axios";

const urlRoot = "/theme-api/menu";

const defaultAPI = Axios.create( {
	baseURL : urlRoot,
	headers : { "Content-Type": "application/json" }
} );

export const finalAPI = {
	apiInstance : defaultAPI,
	list        : ( params, token ) => defaultAPI.get( "", { params: params || {}  } )
};

export default finalAPI;