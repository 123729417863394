<template>
	<li
		:class="[ menuItemClass, { 'active' : showMenu} ]"
		ref="flyout"
	>
		<a
			href="#"
			role="button"
			class="tw-capitalize"
			:class="[
				showSubMenu && isMobile ? 'tw-bg-white tw-px-3 tw-pt-2 tw-pb-5 tw-rounded-t-md tw-text-blue-900 hover:tw-text-blue-900' : '',
				isMobile && getItemLink( overviewLink, currentPath) ? 'tw-text-blue-900 tw-bg-white tw-rounded-md tw-py-2' : 'tw-text-white',
				showSubMenu && isMobile && getItemLink( overviewLink, currentPath) ? ' tw-rounded-t-md tw-rounded-b-none' : '',
				isMobile ? 'tw-text-white tw-w-full tw-flex tw-items-center tw-justify-between tw-px-3 tw-font-bold' : 'tw-text-gray-500 hover:tw-text-gray-900 border-ortus'
			]"
			@click.prevent="getOverviewLink"
		>
			<span class="tw-w-1/2">{{ label }}</span>
			<i
				class="fas tw-h-4 tw-w-1/2 lg:tw-w-4 tw-ml-1 tw-text-right lg:tw-ml-0 lg:tw-text-center"
				aria-hidden="true"
				:class="showSubMenu && isMobile ? 'fa-angle-up' : 'fa-angle-down' "
			/>
		</a>
		<ul
			class="tw-bg-white"
			:class="isMobile ? 'submenu-mobile tw-py-5 tw-rounded-b-md' : 'submenu tw-shadow-lg tw-rounded-lg'"
			v-show="showSubMenu"
		>
			<li class="tw-pb-2">
				<a
					:href="overviewLink"
					class="tw-flex tw-w-full hover:tw-bg-blue-900 tw-py-2"
					:class="[
						isMobile ? 'tw-text-blue-900 tw-pl-3' : 'tw-text-gray-500',
						overviewLink == currentPath ? 'tw-bg-gray-200 tw-flex' : 'tw-text-white'
						]"
				>	
					Overview
				</a>
			</li>
			<li
				v-for="( item, itemIdx ) in items.slice( 0, 9 )"
				:key="'sub-' + itemIdx"
				class="tw-pb-2"
			>
				<a
					:href="item.link"
					class="tw-flex tw-w-full hover:tw-bg-blue-900 tw-py-2"
					:class="[
						isMobile ? 'tw-text-blue-900 tw-pl-3' : 'tw-text-gray-500',
						item.link == currentPath ? 'tw-bg-gray-200 tw-flex' : 'tw-text-white'
						]"
				>
					{{ item.title }}
				</a>
			</li>
			<li
				v-if="items.length > 9"
			>
				<a
					:href="overviewLink"
					class="tw-text-blue-500"
					:class="isMobile ? 'tw-pl-3' : ''"
				>
					View All {{ label }}
				</a>
			</li>
		</ul>
	</li>
</template>
<script>
export default {
	props : {
		columns : {
			type    : Number,
			default : 1
		},
		items : {
			type     : Array,
			required : true
		},
		label : {
			type     : String,
			required : true
		},
		menuItemClass : { type: String, default: "" },
		overviewLink  : { type: String, default: "" },
		isMobile      : {
			type    : Boolean,
			default : false
		},
	},
	data() {
		return { showMenu: false };
	},
	computed : {
		showSubMenu(){
			return this.isMobile ? this.showMenu : true;
		},
		itemId() {
			return Math.round( Math.random() * 10000000 ) + "";
		},
		columnClass() {
			return "md:tw-grid-cols-" + this.columns;
		},
		currentPath() {
			return window.location.href;
		}
	},
	created() {
		window.addEventListener( "click", this.close );
	},
	beforeDestroy() {
		window.removeEventListener( "click", this.close );
	},
	methods : {
		getOverviewLink(){
			if ( screen.width >= 991 && screen.width <= 1253 ) {
				window.location.href = this.overviewLink;
			} else {
				this.toggleMenu();
			}
		},
		toggleMenu( e ){
			this.showMenu = !this.showMenu;
		},
		close( e ){
			if ( ! this.$el.contains( e.target ) ){
				this.showMenu = false;
			}
		},
		getItemLink( pathOverviewLink, CurrentPath ){
			return pathOverviewLink.split( '/' )[ 3 ] == CurrentPath.split( '/' )[ 3 ];
		}
	}
};
</script>