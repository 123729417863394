import { render, staticRenderFns } from "./CoursesExplorer.vue?vue&type=template&id=2271d2c2"
import script from "./CoursesExplorer.vue?vue&type=script&lang=js"
export * from "./CoursesExplorer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports