<template>
	<div class="row">
		<div class="container">
			<div class="tw-relative tw-top-5 md:tw--top-24">
				<div class="tw-bg-white tw-shadow-md tw-rounded-xl tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-between tw-items-center tw-space-y-3 md:tw-space-y-0 tw-p-6">
					<select
						v-model="selectedCategory"
						class="tw-w-full md:tw-w-1/4 tw-ml-1 tw-text-gray-700 tw-mt-1 tw-block tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
					>
						<option
							value=""
						>
							Looking for
						</option>
						<option
							v-for="category in filteredCategory"
							:value="category"
							:key="category"
						>
							{{ category }}
						</option>
					</select>

					<select
						v-model="selectedWhere"
						class="tw-w-full md:tw-w-1/4 tw-ml-1 tw-text-gray-700 tw-mt-1 tw-block tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
					>
						<option
							value=""
						>
							Where
						</option>
						<option
							v-for="location in filteredWhere"
							:value="location"
							:key="location"
						>
							{{ location }}
						</option>
					</select>

					<select
						v-model="selectedWhen"
						class="tw-w-full md:tw-w-1/4 tw-ml-1 tw-text-gray-700 tw-mt-1 tw-block tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-transparent sm:tw-text-sm tw-rounded-md"
					>
						<option
							value=""
						>
							When
						</option>
						<option
							v-for="date in filteredWhen"
							:value="date"
							:key="date"
						>
							{{ date }}
						</option>
					</select>

					<button
						@click="resetFilters"
						class="hover:tw-bg-gray-300 hover:tw-text-white tw-text-gray-400 tw-rounded-lg tw-py-3 tw-px-5 tw-text-center tw-w-full md:tw-w-24"
						title="Resert Filters"
					>
						<i
							class="fas fa-sync tw-text-xl"
							aria-hidden="true"
						/>
					</button>
				</div>
			</div>
		</div>

		<div class="tw-mt-28 md:tw-mt-10">
			<div class="container">
				<h2 class="tw-text-gray-800 tw-text-3xl">
					Upcoming Events
				</h2>

				<div
					v-if="filteredData.length"
					class="tw-flex tw-flex-wrap tw-my-10"
					:class="filteredData.length > 2 ? 'tw-justify-center md:tw-justify-between' : 'tw-justify-around'"
				>
					<event-card
						v-for="(event, index) in filteredData"
						:key="index"
						:event="event"
					/>
				</div>
				<div
					v-else
					class="tw-text-center"
				>
					<p class="tw-text-gray-700 tw-text-3xl">
						<i
							class="far fa-frown"
							aria-hidden="true"
						/>
						No events matches
					</p>
				</div>
			</div>
		</div>

		<div class="tw-mt-20 tw-mb-28">
			<div class="container text-center">
				<h2 class="tw-text-gray-800 tw-text-3xl tw-text-left">
					Webinars
				</h2>

				<div class="webinar-cards tw-my-10 tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
					<webinar-card
						v-for="(webinar, index) in webinars"
						:key="index"
						:webinar="webinar"
					/>
				</div>

				<a
					href="./events/webinars"
					class="tw-text-xl tw-text-blue-500 tw-w-full"
				>
					See all Webinars
					<i
						class="fas fa-arrow-right"
						aria-hidden="true"
					/>
				</a>
			</div>
		</div>

		<!-- Last Events -->
		<!--
		<div class="mobapp-stats-waveup" />
		<div class="stats-container recorded-events-height tw--mb-28 md:tw--mb-20">
			<div class="container tw-text-4xl tw-text-white tw-mb-5">
				<h2 class="tw-text-white">
					Recorded Events
				</h2>
				<p class="tw-text-2xl">
					In case you missed it, check out the recordings.
				</p>

				<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-mt-10">
					<div
						v-for="(event, index) in recordedEvents.filter(x => x !== '')"
						:key="index"
						class="tw-bg-white tw-text-blue-900 tw-rounded-lg tw-cursor-pointer"
					>
						<span
							class="tw-text-lg tw-w-full"
							v-html="event"
						/>
					</div>
				</div>
			</div>
		</div>
		-->
	</div>
</template>
<script>
export default {
	data() {
		return { data: {}, };
	},
	props : {
		events : {
			type     : Array,
			required : true
		},
		webinars : {
			type     : Array,
			required : true
		},
		recordedEvents : {
			type     : Array,
			required : false
		}
	},
	data() {
		return {
			selectedCategory : "",
			selectedWhere    : "",
			selectedWhen     : "",
			eventsArray      : this.filterObject( this.events ),
			eventsData       : []
		};
	},
	computed : {
		filteredCategory(){
			return this.eventsArray.map( item => { return item.categories.reduce( category => category ); } );
		},
		filteredWhere(){
			const locations = this.eventsArray.map(
				item => { return item.customFields.reduce( ( accumulator, customField ) => {
					if ( customField.key == "location" ) {
						return accumulator.indexOf( customField.value ) === -1
						//concat current element to accumulator and return it
							? accumulator.concat( customField.value )
						//Otherwise, just return the actual accumulator array
							: accumulator;
					}
					return accumulator;
				}, "" ); } );

			return locations.filter( ( c, index ) => { return locations.indexOf( c ) === index; } );
		},
		filteredWhen(){
			var options = { year: "numeric", month: "short", day: "numeric" };

			const dates = this.eventsArray.map(
				item => { return item.customFields.reduce( ( accumulator, customField ) => {
					if ( customField.key == "eventDate" ) {
						var eventDate  = new Date( customField.value );
						eventDate.setDate( eventDate.getDate() + 1 );

						accumulator = eventDate.toLocaleDateString( "en-US", options );
					}
					return accumulator;
				}, "" ); } );

			return dates.filter( ( c, index ) => { return dates.indexOf( c ) === index; } );
		},
		filteredData(){
			return this.eventsArray
				.filter(  item => {
					if ( this.selectedCategory.length ){
						var category = this.selectedCategory;
						return item.categories.includes( category );
					}
					return true;
				} )
				.filter(  item => {
					if ( this.selectedWhere.length ){
						return item.customFields.reduce( ( accumulator, customField ) => {
							if ( customField.key == "location" ) {
								accumulator = customField.value;
							}
							return accumulator;
						}, "" ) == this.selectedWhere;
					}
					return true;
				} )
				.filter(  item => {
					if ( this.selectedWhen.length ){
						var options = { year: "numeric", month: "short", day: "numeric" };

						return item.customFields.reduce( ( accumulator, customField ) => {
							if ( customField.key == "eventDate" ) {
								var eventDate  = new Date( customField.value );
								eventDate.setDate( eventDate.getDate() + 1 );

								accumulator = eventDate.toLocaleDateString( "en-US", options );
							}
							return accumulator;
						}, "" ) == this.selectedWhen;
					}
					return true;
				} );
		}
	},
	methods : {
		filterObject( collection ) {
			var result = new Array();
			for ( var j = 0; j < Object.keys( collection ).length; j++ ) {
				result.push( collection[j] );
			}
			return result;
		},
		resetFilters(){
			this.selectedCategory = "";
			this.selectedWhere = "";
			this.selectedWhen = "";
		}
	}
};
</script>