<template>
	<div>
		<div
			class="webinar-card"
		>
			<div class="box-logo tw-rounded-md tw-bg-transparent tw-shadow-2xl hover:tw-bg-light-blue-600 hover:tw-opacity-75">
				<a
					:href="getProductUrl"
					target="_blank"
				>
					<img
						:src="getProductImg"
						:alt="webinar.title"
						class="tw-rounded-md"
					>
				</a>
			</div>
			<div class="webinar-bg-card text-center tw-rounded-t-lg tw-h-48 tw-p-5">
				<div class="tw-h-24 tw-mb-5 tw-flex tw-justify-center tw-items-center">
					<h2 class="tw-text-white tw-font-bold tw-text-lg">
					{{ webinar.title }}
					</h2>
				</div>
				<div class="tw-flex tw-items-center tw-justify-between tw-text-white">
					<div class="tw-mb-0">
						<img
							v-for="( item, index ) in webinar.avatars"
							:key="index"
							:src="item.avatarLink"
							:alt="item.authorName"
							:title="item.authorName"
							class="gravatar img-circle tw-w-7 tw-h-7 tw-mr-1"
						/>
					</div>
					<p class="tw-mb-0">
						<span>
							<i
								class="far fa-calendar"
								aria-hidden="true"
							/>
						</span>
						{{ webinar.webinarDate }}
					</p>
				</div>
			</div>
			<div class="tw-w-full tw-h-14 tw-flex tw-justify-center tw-items-center">
				<button
					@click="showBanner = true"
					type="button"
					class="tw-text-gray-600 tw-font-bold tw-text-md"
				>
					MORE INFO
				</button>
			</div>
		</div>
		<!-- Card Detail -->
		<div
			v-if="showBanner"
			tabindex="0"
			class="tw-z-40 tw-overflow-auto tw-left-0 tw-top-36 xl:tw-top-40 tw-bottom-0 tw-right-0 tw-w-full tw-h-full tw-fixed"
		>
			<div class="tw-z-50 tw-relative tw-p-3 tw-mx-auto tw-my-0 tw-max-w-full tw-w-full md:tw-w-3/4">
				<div class="tw-bg-white tw-rounded-lg tw-shadow-lg tw-flex tw-flex-col tw-overflow-hidden">
					<button
						@click="showBanner = false"
						class="tw-fill-current tw-absolute tw-right-5 tw-top-3 tw-text-3xl tw-font-bold tw-text-gray-400"
					>
						&times;
					</button>
					<div class="tw-p-0 tw-flex-grow">
						<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-0">
							<div class="tw-rounded-l-lg box-img-detail tw-flex tw-justify-center tw-items-center">
								<img
									:src="getProductImg"
									alt="Webinar Logo"
									class="tw-w-1/2 md:tw-w-full"
								>
							</div>
							<div class="tw-col-span-2 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-5">
								<h2
									class="tw-text-blue-800"
									v-text="webinar.title"
								/>
								<h3 class="tw-text-blue-800 tw-text-lg md:tw-text-xl">
									{{ webinar.webinarDate }}
									{{ getSchedule }}
								</h3>
								<p
									class="tw-text-gray-900 tw-text-lg md:tw-text-xl tw-text-center"
									v-text="webinar.content"
								/>
								<p class="tw-text-gray-800 tw-text-center">
									with
									<span
										v-for="( item, index ) in webinar.avatars"
										:key="index"
										v-text="item.authorName + ' '"
									/>
								</p>
								<a
									:href="getRecordingUrl.length ? getRecordingUrl : getRegistrationUrl"
									v-text="getRecordingUrl.length ? 'SEE RECORDING' : 'REGISTER TODAY'"
									class="btn btn-primary"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tw-z-40 tw-overflow-auto tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 tw-w-full tw-h-full tw-fixed tw-bg-black tw-opacity-50" />
		</div>
	</div>
</template>
<script>
export default {
	props : {
		webinar : {
			type     : Object,
			required : true
		}
	},
	data() {
		return { showBanner: false };
	},
	computed : {
		// Method to build information about webinar schedule and location
		getSchedule(){
			var schedule = this.webinar.customFields.filter( ( item ) => item.key == "Schedules" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );

			var location = this.webinar.customFields.filter( ( item ) => item.key == "Location" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );

			return "Time: " + schedule + " " + location;
		},
		// Method to get the product image URL
		getProductImg(){
			var algo = this.webinar.customFields.filter( ( item ) => item.key == "productImg" || item.KEY == "productImg"  )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );
			return algo;
		},
		// Method to get the product URL as official documentation
		getProductUrl(){
			return this.webinar.customFields.filter( ( item ) => item.key == "productUrl" || item.KEY == "productUrl" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );
		},
		// Method to get the webinar recording URL
		getRecordingUrl(){
			return this.webinar.customFields.filter( ( item ) => item.key == "RecordingUrl" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );
		},
		// Method to get the webinar registration URL
		getRegistrationUrl(){
			return this.webinar.customFields.filter( ( item ) => item.key == "registrationUrl" )
				.reduce( ( acc , value ) => {
					acc += value.value;
					return acc;
				}, "" );
		}
	}
};
</script>