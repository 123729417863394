<template>
	<div>
		<div
			:id="carouselId"
			class="owl-carousel"
			:class="carouselClass"
			v-if="isLoading"
		>
			<div class="pic">
				Images loading...
			</div>
		</div>
		<div
			:id="carouselId"
			class="owl-carousel"
			:class="carouselClass"
			v-else
		>
			<div
				v-for="( item, itemIdx ) in slides"
				:key="carouselId + '-' + itemIdx"
				class="item"
				:class="[ item.isDark ? 'text-white' : '' ]"
				:style="getStyleObject( item )"
			>
				<div
					v-if="item.imageURL && item.imageURL != ''"
					class="col-sm-4"
					:class="'pull-' + item.imagePosition"
				>
					<div class="slide-pic">
						<img
							class="img-responsive"
							:src="item.imageURL"
							:alt="item.title"
						/>
					</div>
				</div>
				<div
					class="slide-content"
					:class="[ item.imageURL != '' ? 'col-sm-8' : 'col-sm-12']"
				>
					<h1
						v-if="titleHeadingLevel == 1"
						class="slide-title"
					>
						{{ item.title }}
					</h1>
					<h2
						v-else-if="titleHeadingLevel == 2"
						class="slide-title"
					>
						{{ item.title }}
					</h2>
					<h3
						v-else-if="titleHeadingLevel == 3"
						class="slide-title"
					>
						{{ item.title }}
					</h3>

					<div
						class="slide-description"
						v-html="item.content"
					/>
					<div class="slide-buttons">
						<a
							v-if="item.button1URL != ''"
							class="btn btn-success"
							:href="item.button1URL"
						>

							<span v-if="item.button1Text != ''">{{ item.button1Text }}</span>
							<span v-else>Go</span>
						</a>
						<a
							v-if="item.button2URL != ''"
							class="btn btn-info"
							:href="item.button2URL"
						>

							<span v-if="item.button2Text != ''">{{ item.button2Text }}</span>
							<span v-else>More</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Owl from "owl.carousel";

export default {
	props : {
		carouselId : { 				// unique carousel Id
			type     : String,
			required : true
		},
		carouselClass : {			// carousel class
			type : String
		},
		slides : {					// file list ( array of file names )
			type : Array
		},
		itemsOnStage : {				// items on screen
			type    : Number,
			default : 4
		},
		loop : {
			type    : Boolean,
			default : false
		},
		margin : {					// right marging of each item
			type    : Number,
			default : 4
		},
		titleHeadingLevel : {					// right marging of each item
			type    : Number,
			default : 1
		},
		showNav : {					// show nav's prev and next buttons
			type    : Boolean,
			default : false
		},
		autoPlay : {
			type    : Boolean,
			default : false
		},
		navContainer : {
			type    : String,
			default : ""
		},
		animateIn : {
			type    : String,
			default : ""
		},
		animateOut : {
			type    : String,
			default : ""
		}
	},
	data() {
		return {
			isLoading    : false,
			carouselData : this.fileList
		};
	},
	mounted() {
		this.$nextTick( function(){
			this.installOwlCarousel();
		} );
	},
	methods : {
		getStyleObject( item ){
			var style = {};
			if ( item.backgroundColor != "" ){
				style.backgroundColor = item.backgroundColor;
			} else {
				style.backgroundColor = "#fff";
			}
			if ( item.backgroundImage != "" ){
				style.backgroundImage = "url( \"" + item.backgroundImage + "\")";
			}
			return style;
		},
		installOwlCarousel : function(){
			var owlProperties = {
				loop       : this.loop,
				items      : this.itemsOnStage,
				margin     : this.margin,
				responsive : {
					0    : { items: 1 },
					600  : { items: this.itemsOnStage - 1 },
					1000 : { items: this.itemsOnStage }
				},
				responsiveRefreshRate : 200,
				autoplay              : this.autoPlay,
				autoplayHoverPause    : false,
				nav                   : this.showNav,
				navContainer          : this.navContainer
			};

			if ( this.animateIn != "" ){
				owlProperties.animateIn = this.animateIn;
			}

			if ( this.animateOut != "" ){
				owlProperties.animateOut = this.animateOut;
			}

			$( "#" + this.carouselId ).owlCarousel( owlProperties );
		}
	}
};
</script>