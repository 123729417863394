<template>
	<div>
		<div class="row bg-courses-head tw-bg-no-repeat">
			<div class="container tw-mx-auto tw-text-white">
				<!-- breadcrumb -->
				<div class="tw-flex tw-items-center tw-space-x-1 md:tw-space-x-3 tw-my-3 tw-text-white tw-mb-0">
					<a
						href="/"
						target="_self"
						class="tw-text-white"
					>
						<i
							class="fas fa-home"
							aria-hidden="true"
						/>
					</a>
					<i
						class="fas fa-chevron-right tw-text-sm"
						aria-hidden="true"
					/>
					<a
						href="/learn"
						target="_self"
						class="tw-text-white"
					>
						Learn
					</a>
					<i
						class="fas fa-chevron-right tw-text-sm"
						aria-hidden="true"
					/>
					<a
						href="/learn/courses"
						target="_self"
						class="tw-text-white"
					>
						Courses
					</a>
					<i
						class="fas fa-chevron-right tw-text-sm"
						aria-hidden="true"
					/>
					<span class="tw-text-white">
						{{ course.title }}
					</span>
				</div>

				<div class="tw-w-full lg:tw-w-3/4 tw-text-center md:tw-text-left tw-mt-2 tw-space-y-3">
					<h2 class="tw-text-2xl md:tw-text-3xl tw-text-white tw-mt-1 md:tw-mt-3">
						{{ course.title }}
					</h2>
					<p class="tw-text-center md:tw-text-left tw-text-sm md:tw-text-lg tw-leading-relaxed md:tw-leading-normal">
						{{ course.description }}
					</p>
					<a
						:href="getCustomFieldValue( course, 'pdfUrl' )"
						target="_blank"
						v-if="getCustomFieldValue( course, 'pdfUrl' ).trim().length"
						class="tw-no-underline tw-text-white tw-font-bold"
					>
						DOWNLOAD PDF
						<i
							class="fas fa-download tw-ml-2"
							aria-hidden="true"
						/>
					</a>
				</div>
			</div>
		</div>

		<div class="container tw-mx-auto tw-my-20">
			<div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-1">
				<div class="tw-w-full lg:tw-w-3/4 tw-order-2 lg:tw-order-1 tw-mt-3 lg:tw-mt-0">
					<div v-html="course.content" />

					<h2
						v-if="course.instructors.length"
						class="tw-my-10 tw-text-gray-800"
					>
						Instructors
					</h2>
					<div
						v-for="instructor in course.instructors"
						:key="instructor.avatarLink"
						class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-1 tw-mt-8"
					>
						<div class="text-center tw-h-full tw-w-full">
							<img
								:src="instructor.avatarLink"
								:alt="instructor.name"
								class="tw-rounded-full tw-w-32 tw-h-32"
							>
							<div
								class="text-center tw-space-x-3 tw-mt-6"
								v-if="instructor.preferences.length"
							>
								<a
									target="_blank"
									class="tw-text-blue-500 tw-text-xl"
									:title="instructor.name + ' - Facebook'"
									:href="JSON.parse( instructor.preferences ).facebook"
									v-if="JSON.parse( instructor.preferences ).facebook.length"
								>
									<i
										class="fab fa-facebook-f"
										aria-hidden="true"
									/>
								</a>
								<a
									target="_blank"
									class="tw-text-blue-500 tw-text-xl"
									:title="instructor.name + ' - Twitter'"
									:href="JSON.parse( instructor.preferences ).twitter"
									v-if="JSON.parse( instructor.preferences ).twitter.length"
								>
									<i
										class="fab fa-twitter"
										aria-hidden="true"
									/>
								</a>
								<a
									target="_blank"
									class="tw-text-blue-500 tw-text-xl"
									:title="instructor.name + ' - Linkedin'"
									:href="JSON.parse( instructor.preferences ).linkedin"
									v-if="JSON.parse( instructor.preferences ).linkedin.length"
								>
									<i
										class="fab fa-linkedin-in"
										aria-hidden="true"
									/>
								</a>
							</div>
						</div>
						<div class="tw-col-span-2 tw-h-full tw-w-full">
							<p
								v-text="instructor.name"
								class="tw-text-gray-800 tw-font-bold tw-text-lg"
							/>
							<p
								v-text="instructor.biography"
								class="tw-text-gray-800 tw-text-md"
							/>
						</div>
					</div>
				</div>
				<div class="tw-bg-indigo-100 tw-rounded-md tw-p-3 xl:tw-p-0 tw-w-full lg:tw-w-1/4 tw-order-1 lg:tw-order-2">
					<div class="tw-float-right tw-top-20 tw-sticky">
						<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-1 tw-gap-4 md:tw-gap-1 xl:tw-p-3 tw-w-full tw-h-auto tw-rounded-md">
							<div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
								<img
									:alt="course.title"
									:src="getCustomFieldValue( course, 'productUrl' )"
									class="tw-w-24"
								>
								<p class="tw-text-lg lg:tw-text-xl tw-text-gray-800 tw-my-3">
									<span class="tw-font-bold">${{ getCustomFieldValue( course, 'price' ) }}</span>
									Per Student
								</p>
								<a
									href="##"
									style="margin: 0px"
									data-contenttype="contact"
									data-contenturl="/modals/modal-contact"
									data-target="#generalModal"
									:data-title="course.title"
									data-toggle="modal"
									class="btn btn-primary tw-w-full tw-my-3"
								>
									BOOK NOW
								</a>
							</div>
							<div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-base tw-my-3 xl:tw-mt-5">
								<table>
									<tr>
										<td><span class="tw-font-bold">Course Format:</span></td>
										<td>&nbsp;<span>{{ getCustomFieldValue( course, "format") }}</span></td>
									</tr>
									<tr>
										<td><span class="tw-font-bold">Level:</span></td>
										<td>&nbsp;<span>{{ getCustomFieldValue( course, "level") }}</span></td>
									</tr>
									<tr>
										<td><span class="tw-font-bold">Training Time:</span></td>
										<td>
										&nbsp;
											<span>
												{{ getCustomFieldValue( course, "days") }} days |
												{{ getCustomFieldValue( course, "hours") }} hours
											</span>
										</td>
									</tr>
									<tr>
										<td><span class="tw-font-bold">Min. Students:</span></td>
										<td>&nbsp;<span>{{ getCustomFieldValue( course, "minStudents") }}</span></td>
									</tr>
									<tr>
										<td><span class="tw-font-bold">Price:</span></td>
										<td>&nbsp;<span>${{ getCustomFieldValue( course, "price") }}</span></td>
									</tr>
								</table>
							</div>
							<div class="tw-bg-white tw-rounded-md tw-px-1 xl:tw-px-3 tw-pt-5 tw-pb-14 xl:tw-mt-5">
								<div class="tw-mx-auto text-center">
									<p class="tw-font-bold tw-m-0 tw-text-sm">
										Available
									</p>
									<img
										src="/__media/2022/learn/overview/logo-ortus-cfcasts.svg"
										alt="CFCASTS"
										class="tw-w-32"
									>
								</div>
								<div class="tw-mx-auto tw-text-sm tw-mt-5 tw-px-1">
									<p>
										<i
											class="far fa-play-circle tw-w-6"
											aria-hidden="true"
										/>
										Videos ({{ getCustomFieldValue( course, "days") }} days | {{ getCustomFieldValue( course, "hours") }} hours)
									</p>
									<p>
										<i
											class="far fa-smile-beam tw-w-6"
											aria-hidden="true"
										/>
										Online and at your own place
									</p>
									<p>
										<i
											class="fas fa-mobile tw-w-6"
											aria-hidden="true"
										/>
										Available on the App
									</p>
									<p>
										<i
											class="fas fa-infinity tw-w-6"
											aria-hidden="true"
										/>
										Unlimited access forever
									</p>
									<p>
										<i
											class="fas fa-medal tw-w-6"
											aria-hidden="true"
										/>
										Certificate upon completion of the course
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props : {
		course : {
			type     : Object,
			required : true
		}
	},
	data() {
		return { data: "" };
	},
	methods : {
		getCustomFieldValue( obj, key ){
			return Object.values( obj.customFields ).reduce( ( accumulator, customField ) => {
				if ( customField.key == key ) {
					accumulator = customField.value;
				}
				return accumulator;
			}, "" );
		}
	}
};
</script>