<template>
	<div
		class="event-card"
	>
		<div class="event-bg-card text-center tw-rounded-t-lg tw-h-48 tw-p-5">
			<img
				:src="eventImgUrl()"
				class="tw-w-24"
				:alt="event.title | truncate( 25, '...' )"
				:title="event.title"
			>
		</div>
		<div class="tw-w-full tw-h-24 tw-p-3">
			<div class="tw-grid tw-grid-rows-2 tw-grid-flow-col tw-gap-2 tw-h-20 tw-text-gray-600">
				<div class="tw-row-span-3 tw-h-20 tw-w-16 tw-font-bold">
					<p class="tw-mb-0 tw-text-blue-900">
						{{ eventDate() }}
					</p>
				</div>
				<div class="tw-col-span-2 tw-h-20">
					<p class="tw-mb-0 tw-text-left tw-leading-tight tw-font-bold tw-text-blue-900">
						{{ event.title | truncate( 25, '...' ) }}
					</p>
					<p class="tw-mb-0 tw-text-left tw-text-sm tw-text-blue-900">
						with {{ eventAuthor() }}
					</p>
					<p class="tw-mb-0 tw-text-right tw-text-sm">
						<a
							:href="eventInfoUrl()"
							target="_blank"
							class="tw-text-blue-500 tw-font-bold"
						>
							More info
						</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props : {
		event : {
			type     : Object,
			required : true
		},
	},
	methods : {
		eventDate(){
			var date = this.event.customFields.reduce( ( accumulator, customField ) => {
				if ( customField.key == "eventDate" ) {
					accumulator = customField.value;
				}
				return accumulator;
			}, "" );

			var options = { year: "numeric", month: "short", day: "numeric" };
			var eventDate  = new Date( date );
			eventDate.setDate( eventDate.getDate() + 1 );

			return eventDate.toLocaleDateString( "en-US", options );
		},
		eventAuthor(){
			return this.event.customFields.reduce( ( accumulator, customField ) => {
				if ( customField.key == "author" ) {
					accumulator = customField.value;
				}
				return accumulator;
			}, "" );
		},
		eventInfoUrl(){
			return this.event.customFields.reduce( ( accumulator, customField ) => {
				if ( customField.key == "moreInfo" ) {
					accumulator = customField.value;
				}
				return accumulator;
			}, "" );
		},
		eventImgUrl(){
			return this.event.customFields.reduce( ( accumulator, customField ) => {
				if ( customField.key == "imageUrl" ) {
					accumulator = customField.value;
				}
				return accumulator;
			}, "" );
		},
	}
};
</script>