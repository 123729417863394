<template>
	<div
		class="tw-flex tw-relative tw-h-full"
		:class="mainDirection"
	>
		<div class="tw-w-full">
			<slot />
		</div>

		<div
			v-if="controls"
			class="tw-flex tw-justify-center tw-items-center tw-p-3 lg:tw-p-0 tw-absolute tw-bottom-0"
			:class="btnDirection"
		>
			<button
				class="tw-w-16 tw-h-10 tw-font-bold tw-inline-flex tw-items-center tw-justify-center tw-px-2 tw-py-1 tw-border-2 tw-border-white tw-rounded-md tw-text-white tw-bg-transparent hover:tw-bg-white hover:tw-text-gray-500 tw-text-md"
				title="Previous"
				@click="prev"
			>
				<svg
					class="tw-w-6 tw-h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				><path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M15 19l-7-7 7-7"
				/></svg>
			</button>
			<button
				class="tw-w-16 tw-h-10 tw-font-bold tw-inline-flex tw-items-center tw-justify-center tw-px-2 tw-py-1 tw-border-2 tw-border-white tw-rounded-md tw-text-white tw-bg-transparent hover:tw-bg-white hover:tw-text-gray-500 tw-text-md"
				title="Next"
				@click="next"
			>
				<svg
					class="tw-w-6 tw-h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				><path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M9 5l7 7-7 7"
				/></svg>
			</button>
		</div>
	</div>
</template>
<script>
export default {
	props : {
		// Value to show or hide ( next, prev ) buttons
		controls : {
			type    : Boolean,
			default : true
		},
		/*  Value of position to show next and prev buttons
            @values: left | top | right | bottom
         */
		controlsPosition : {
			type    : String,
			default : "bottom"
		},
		// Interval time to pass each slide
		intervalTime : {
			type    : Number,
			default : 6000
		},
		/*  Type of transition to pass each slide
            @values: fade | slide-fade | shiftx
         */
		transition : {
			type    : String,
			default : "fade",
			require : true
		},
	},
	data() {
		return {
			timer       : null, // it receives the set time to pass each slide
			activeIndex : 0,    // index active of current slide
		};
	},
	computed : {
		// Get all slides children of Slider
		slides() {
			return this.$slots.default
				.filter( ( c ) => c.componentOptions && c.componentOptions.tag === "slide" )
				.map( ( c ) => c.componentInstance );
		},
		// Get count of all slides
		slidesCount() {
			return this.slides.length;
		},
		/**
         * Validate the next index to move the slide,
         * if it passes to the limit, it's returned to the first index.
        */
		nextIndex() {
			const nextIndex = this.activeIndex + 1;
			return nextIndex <= this.slidesCount - 1 ? nextIndex : 0;
		},
		/*
            Validate the next index to move the slide,
            if it passes to the limit, it's returned to the first index.
        */
		prevIndex() {
			const prevIndex = this.activeIndex - 1;
			return prevIndex >= 0 ? prevIndex : this.slidesCount - 1;
		},

		/**
         *  Return classes to move the position of controls,
         *  according to the controlsPosition property.
        */
		btnDirection(){
			if ( this.controlsPosition == "left" || this.controlsPosition == "right" ) {
				return "tw-flex-col tw-h-auto tw-w-1/6 tw-space-y-3";
			} else {
				return "tw-flex-row tw-w-full tw-h-auto tw-space-x-3";
			}
		},

		/**
         * Classes to move the position of main content,
         * according to the controlsPosition property.
         */
		mainDirection(){
			var direction = "";
			switch ( this.controlsPosition ) {
			case "left":
				direction = "tw-flex-row-reverse";
				break;
			case "right":
				direction = "tw-flex-row";
				break;
			case "top":
				direction = "tw-flex-col-reverse";
				break;
			case "bottom":
				direction = "tw-flex-col";
				break;
			}
			return direction;
		}
	},
	mounted() {
		// Immediately activate the first slide.
		this.goToIndex( this.activeIndex );
		if ( !this.controls ) { this.startSlide(); }
	},
	methods : {
		// method to start the passage of each slide automatically according to the time interval property
		startSlide() {
			this.timer = setInterval( this.next, this.intervalTime );
		},
		// Method to hide the slide passed and show the current slide
		goToIndex( index ) {
			// Find out the direction we're moving.
			const direction = index > this.activeIndex ? "left" : "right";
			// Call the `hide()` method on the Slide component
			this.slides[this.activeIndex].hide( this.transition );
			// Call the `show()` method on the Slide component
			this.slides[index].show( this.transition );
			this.activeIndex = index;
		},
		// Pass to the next slide
		next() {
			this.goToIndex( this.nextIndex );
		},
		// Go back to the last slide
		prev() {
			this.goToIndex( this.prevIndex );
		},
	},
	// Provide contextual information from the component directly.
	render() {
		return this.$scopedSlots.default( {
			// Data
			activeIndex : this.activeIndex,
			// Methods
			goToIndex   : this.goToIndex,
			next        : this.next,
			prev        : this.prev,
		} );
	},
};
</script>